import Api from "../api/Api";
import {all, call, put, select} from "redux-saga/effects";
import {CREATE_COURSE_FAILED, CREATED_COURSE_SUCCESS, DELETED_COURSE, HIDE_MODAL, LIST_COURSES_COMPLETE} from "../actions";
import {error, success} from "react-notification-system-redux";
import {hideModalCreator, showModalCreator} from "../reducers/modalReducer";
import {createCourseCategoryFailedCreator, createCourseCategorySuccessCreator} from "../actionCreators/courseActionCreators";

const isAdmin = state => state.sua.getIn(["user", "roles"]).includes("ADMIN");

export function* coursesList() {
    console.log("COURSES LIST CALLED");

    const _isAdmin = yield select(isAdmin);

    if (_isAdmin) {
        const {courses, coaches, courseCategories} = yield all({
            courses: call(Api.fetchCourses), coaches: call(Api.fetchCoaches),
            courseCategories: call(Api.fetchCourseCategories)
        });
        yield put({type: LIST_COURSES_COMPLETE, courses: courses, coaches: coaches, courseCategories: courseCategories});
    } else {
        const courses = yield call(Api.fetchCoursesForOwner);
        yield put({type: LIST_COURSES_COMPLETE, courses: courses});
    }
}

export function* coursesDelete(action) {
    const {courseDelete, modalName} = action;

    try {
        yield call(Api.deleteCourse, courseDelete.get('businessKey'));
        yield put(success({
            title: 'Kurs gelöscht',
            message: `Der Kurs ${courseDelete.get('name')} wurde erfolgreich gelöscht.`
        }));
        yield put(hideModalCreator(modalName));

        const courses = yield call(Api.fetchCourses);

        yield put({type: DELETED_COURSE, courses: courses});
    } catch (errorCourseDelete) {
        
        yield put(error({
            title: 'Fehler aufgetreten',
            message: `Beim Löschen des Kurses ${courseDelete.get('name')} ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.`,
        }));
    }
}

export function* courseCategoryCreate(action) {
    try {
        const businessKey = yield call(Api.createCourseCategory, action.name);
        console.log("business key in saga", businessKey);

        const courseCategories = yield call(Api.fetchCourseCategories);

        yield put(createCourseCategorySuccessCreator(courseCategories));

        yield put(success({
            title: 'Neue Kategorie',
            message: `Die neue Kategorie ${action.name} wurde erfolgreich angelegt`
        }));

        action.courseCategoryCreated(businessKey);
    } catch (courseCategoryCreateError) {
        yield put(createCourseCategoryFailedCreator());

        yield put(error({
            title: 'Fehler',
            message: 'Fehler beim Anlegen der neuen Kategorie. Bitte versuchen Sie es später erneut'
        }));
    }
}

export function* coursesCreate(action) {
    const {course, resetForm, setSubmitting, modalName, newCoachInfoModalName} = action;

    try {
        let courseResponse;

        if (course.businessKey) {
            courseResponse = yield call(Api.updateCourse, course.businessKey, action.newValues);

            yield put(success({
                title: 'Kurs bearbeitet',
                message: `Der Kurs ${course.name} wurde erfolgreich bearbeitet`
            }));
        } else {
            courseResponse = yield call(Api.createCourse, action.newValues);

            yield put(success({
                title: 'Neuen Kurs hinzugefügt',
                message: `Der Kurs ${course.name} wurde erfolgreich hinzugefügt`
            }));
        }

        setSubmitting(false);
        resetForm({});
        const courses = yield call(Api.fetchCourses);
        yield put({type: CREATED_COURSE_SUCCESS, courses: courses});

        yield put(hideModalCreator(modalName));
        if (!course.coach.businessKey) {
            yield put(showModalCreator(newCoachInfoModalName, {activationUrl: courseResponse.activationUrl}));
        }
    } catch (errorCourseCreate) {
        yield put({type: CREATE_COURSE_FAILED});

        if (course.businessKey) {
            yield put(error({
                title: 'Fehler aufgetreten',
                message: 'Beim Bearbeiten eines Kurses ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
            }));
        } else {
            yield put(error({
                title: 'Fehler aufgetreten',
                message: 'Beim Hinzufügen eines neuen Kurses ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
            }));
        }
    }
}