import React from "react";
import {connect} from "react-redux";
import ServerSideTable from "../../components/ServerSideTable";
import {List, Map} from "immutable";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";
import Moment from "react-moment";

class CourseInfoModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {courseInfo, name, activeSeason, tableName, isOpen, backdrop, hideModal} = this.props,
            participants = courseInfo ? courseInfo.get('participants') || List() : List();

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Teilnehmer eines Kurses</ModalHeader>
            <ModalBody>
                Teilnehmer für Kurs <strong>{name}</strong>:
                <ServerSideTable columns={List([
                    Map([
                        ['key', 'lastname'],
                        ['display', 'Nachname']
                    ]), Map([
                        ['key', 'firstname'],
                        ['display', 'Vorname']
                    ]), Map([
                        ['key', 'birthdate'],
                        ['display', 'Geburtsdatum'],
                        ['valueConverter', (val) => val ?
                            <Moment format="DD.MM.YYYY">{val}</Moment> : ""]
                    ]), Map([
                        ['key', 'paymentType'],
                        ['display', 'Beitrag'],
                        ['valueConverter', (paymentType, fullData) => {
                            const amount = activeSeason && paymentType ?
                                paymentType.get('amounts').last().get('amount') : "unbekannt";

                            return fullData.get('payments') && fullData.get('payments').get(activeSeason.get('season').toString()) ?
                                <>{amount} <i className="far fa-check-circle alert-success"></i></> :
                                <>{amount} <i className="far fa-times-circle alert-danger"></i></>;
                        }]
                    ])])} tableName={tableName} values={participants} isLocal={true} pageSize={25}>
                </ServerSideTable>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color='primary' onClick={() => hideModal()}>Schließen</Button>
            </ModalFooter>
        </Modal>
    }
}

CourseInfoModal = connect((state, ownProps) => ({
    courseInfo: state.modal.getIn([ownProps.modalName, 'courseInfo']),
    activeSeason: state.sua.get("activeSeason"),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName))
}))(CourseInfoModal);

export default CourseInfoModal;