import React from "react";
import {connect} from "react-redux";
import {Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {customInputForm, customReactCreatableSelect, customReactSelect} from "../../customComponents";
import {SEX} from "../../enums";
import {CREATE_OR_UPDATE_COURSE} from "../../actions";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";
import {Map} from 'immutable';
import {boolean, object, string} from "yup";
import {createCourseCategoryCreator} from "../../actionCreators/courseActionCreators";

class AddCourseModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isCreateNewCoach: false
        };

        this.CourseSchema = object().shape({
            name: string().required('Pflichtfeld'),
            courseCategory: string(),
            isCreateNewCoach: boolean(),
            coach: object().when('isCreateNewCoach', {is: false, then: object().required('Pflichtfeld')}),
            newCoachFirstname: string().when('isCreateNewCoach', {is: true, then: string().required('Pflichtfeld')}),
            newCoachLastname: string().when('isCreateNewCoach', {is: true, then: string().required('Pflichtfeld')}),
            newCoachEmail: string().email().when('isCreateNewCoach', {is: true, then: string().required('Pflichtfeld')}),
            newCoachSex: object().when('isCreateNewCoach', {is: true, then: object().required('Pflichtfeld')})
        });

        const {initModal} = this.props;

        initModal();
    }

    onChange(selectedOption, action) {
        this.props.changeCoach(selectedOption, this.props.coaches);
    }

    toggleIsCreateNewCoach() {
        this.setState((prevState, props) => ({
            isCreateNewCoach: !prevState.isCreateNewCoach
        }));
    }

    getCourseCategoryOptions() {
        const {courseCategories} = this.props;

        return courseCategories ? courseCategories.map(courseCategory => ({
            label: courseCategory.get('name'),
            value: courseCategory.get('businessKey')
        })) : [];
    }

    getSortedCoaches() {
        const {coaches} = this.props;

        return coaches ? coaches.sort((a, b) => (!a.get("lastname") ? -1 : !b.get("lastname") ? 1 :
            a.get("lastname").toLowerCase().localeCompare(b.get("lastname").toLowerCase())))
            .map((val) => ({
                value: val.get("businessKey"),
                label: `${val.get('lastname')} ${val.get('firstname')}`
            })).toList().toJS() : [];
    }

    getTitle() {
        return this.isEditMode() ? "Kurs bearbeiten" : "Neuen Kurs hinzufügen";
    }

    isEditMode() {
        const {courseEdit} = this.props;

        return courseEdit && courseEdit.get('businessKey');
    }

    getDescription() {
        return this.isEditMode()  ? "Bitte ändern Sie hier die gewünschten Daten des Kurses" : "Bitte geben Sie die Daten des neuen Kurses ein:";
    }

    getSuccessButtonText() {
        return this.isEditMode()  ? "Speichern" : "Hinzufügen";
    }

    createNewCourseCategory(name, setFieldValue) {
        const {createCourseCategory} = this.props;

        createCourseCategory(name, businessKey => setFieldValue('courseCategory', {
            label: name,
            value: businessKey
        }));
    }

    initCourseProp(prop, converterFunc) {
        const {courseEdit} = this.props;

        return this.isEditMode() && courseEdit.get(prop) ? (converterFunc ? converterFunc(courseEdit.get(prop)) : courseEdit.get(prop)) : '';
    }

    render() {
        const {hideModal, isOpen, backdrop, createCourse, modalName, courseEdit} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>{this.getTitle()}</ModalHeader>
            <Formik enableReinitialize
                    initialValues={{
                        name: this.initCourseProp('name'),
                        courseCategory: this.initCourseProp('courseCategory', courseCategory => ({
                            value: courseCategory.get('businessKey'),
                            label: courseCategory.get('name')
                        })),
                        coach: this.initCourseProp('coach', coach => ({
                            value: coach.get("businessKey"),
                            label: `${coach.get('lastname')} ${coach.get('firstname')}`
                        })),
                        newCoachFirstname: '',
                        newCoachLastname: '',
                        newCoachEmail: '',
                        newCoachSex: '',
                        isCreateNewCoach: false
                    }}
                    validationSchema={this.CourseSchema}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        console.log(values);
                        if (values.isCreateNewCoach === true) {
                            createCourse({
                                businessKey: courseEdit ? courseEdit.get('businessKey') : undefined,
                                name: values.name,
                                courseCategory: values.courseCategory ? {
                                    name: values.courseCategory.label,
                                    businessKey: values.courseCategory.value
                                } : null,
                                coach: {
                                    firstname: values.newCoachFirstname,
                                    lastname: values.newCoachLastname,
                                    sex: values.newCoachSex.value,
                                    email: values.newCoachEmail
                                }
                            }, setSubmitting, resetForm, modalName);
                        } else {
                            createCourse({
                                businessKey: courseEdit ? courseEdit.get('businessKey') : undefined,
                                name: values.name,
                                courseCategory: values.courseCategory ? {
                                    name: values.courseCategory.label,
                                    businessKey: values.courseCategory.value
                                } : null,
                                coach: {
                                    businessKey: values.coach.value
                                }
                            }, setSubmitting, resetForm, modalName);
                        }
                    }}>
                {({isSubmitting, setFieldValue, errors, touched}) => (
                    <Form id="add-payment-type-modal-form" role="form">
                        <ModalBody>
                            <h3 className='mb-3'>{this.getDescription()}</h3>

                            <fieldset id="add-payment-type-modal-fieldset" className="form-group">
                                {/*<legend>Beitragsgruppe</legend>*/}
                                <FormGroup>
                                    <Label for='name'>Bezeichnung</Label>
                                    <Field name="name" component={customInputForm} type="text"
                                           placeholder="Bezeichnung..."/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for='courseCategory'>Kursart</Label>
                                    <Field name='courseCategory' component={customReactCreatableSelect}
                                           onCreateOption={(val) => this.createNewCourseCategory(val, setFieldValue)}
                                           options={this.getCourseCategoryOptions()}/>
                                </FormGroup>

                                {!this.state.isCreateNewCoach && <FormGroup>
                                    <Label for='coach'>Kursleiter</Label>
                                    <Field id='coach' name='coach'
                                           component={customReactSelect}
                                           options={this.getSortedCoaches()}
                                    />
                                </FormGroup>}
                                <FormGroup row>
                                    <Label for="isCreateNewCoach" sm={6}>Neuen Kursleiter anlegen</Label>
                                    <Col sm={4}>
                                        <Field id="isCreateNewCoach" name="isCreateNewCoach" className="form-control" component="input"
                                               type="checkbox"
                                               checked={this.state.isCreateNewCoach}
                                               onClick={() => this.toggleIsCreateNewCoach()}/>
                                    </Col>
                                </FormGroup>
                                {this.state.isCreateNewCoach && <><FormGroup>
                                    <Label for="newCoachFirstname">Vorname</Label>
                                    <Field id="newCoachFirstname" name="newCoachFirstname" component={customInputForm} type="text"
                                           placeholder="Vorname..."/>
                                </FormGroup>
                                    <FormGroup>
                                        <Label for="newCoachLastname">Nachname</Label>
                                        <Field id="newCoachLastname" name="newCoachLastname" component={customInputForm} type="text"
                                               placeholder="Nachname..."/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newCoachEmail">E-Mail</Label>
                                        <Field id="newCoachEmail" name="newCoachEmail" component={customInputForm} type="text"
                                               placeholder="E-mail..."/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="newCoachSex">Geschlecht</Label>
                                        <Field id="newCoachSex" name='newCoachSex' component={customReactSelect} options={[
                                            SEX.MALE,
                                            SEX.FEMALE
                                        ]}/>
                                    </FormGroup></>}
                            </fieldset>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color='secondary' onClick={() => hideModal()} disabled={isSubmitting}>Abbrechen</Button>
                            <Button type="submit" color='primary' disabled={isSubmitting}>{this.getSuccessButtonText()}</Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    }
}

export default connect((state, ownProps) => ({
    coaches: state.sua.get("coaches"),
    courseCategories: state.sua.get('courseCategories'),
    courseEdit: state.modal.getIn([ownProps.modalName, 'courseEdit']) || Map(),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
    createCourseCategory: (name, courseCategoryCreated) => dispatch(createCourseCategoryCreator(name, courseCategoryCreated)),
    createCourse: (course, setSubmitting, resetForm) => dispatch({
        type: CREATE_OR_UPDATE_COURSE,
        course: course,
        newValues: {
            name: course.name,
            coach: course.coach,
            isCreateNewCoach: course.isCreateNewCoach,
            registerBaseUrl: `${window.location.origin}/register`,
            courseCategory: course.courseCategory
        },
        setSubmitting: setSubmitting,
        resetForm: resetForm,
        modalName: ownProps.modalName,
        newCoachInfoModalName: ownProps.newCoachInfoModalName
    })
}))(AddCourseModal);