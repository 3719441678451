export const FETCH_COURSES_FOR_COACH = 'FETCH_COURSES_FOR_COACH',
    FETCH_COURSES_FOR_COACH_SUCCESS = 'FETCH_COURSES_FOR_COACH_SUCCESS',
    FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA',
    FETCH_DASHBOARD_DATA_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS';
export const fetchCoursesForCoachCreator = (id) => ({
    type: FETCH_COURSES_FOR_COACH,
    id: id
});
export const fetchCoursesForCoachSuccessCreator = (courses, id) => ({
    type: FETCH_COURSES_FOR_COACH_SUCCESS,
    id: id,
    courses: courses
})
export const fetchDashboardDataCreator = (season) => ({
    type: FETCH_DASHBOARD_DATA,
    season: season
});
export const fetchDashboardDataSuccessCreator = (dashboardData) => ({
    type: FETCH_DASHBOARD_DATA_SUCCESS,
    dashboardData: dashboardData
})