import React from "react";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {DELETE_MEMBER} from "../../actions";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";

class DeleteMemberModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {deleteMember, member, isOpen, backdrop, hideModal} = this.props,
            memberName = member ? `${member.get('firstname')} ${member.get('lastname')}` : '';

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Mitglied löschen</ModalHeader>
            <ModalBody>
                Wollen Sie das Mitglied "<strong>{memberName}</strong>" wirklich unwiderruflich
                löschen? Alle zu diesem Mitglied gespeicherten Daten gehen dabei verloren!
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="secondary" onClick={hideModal}>Abbrechen</Button>
                <button type="button" color='primary' onClick={() => deleteMember(member)}>Löschen</button>
            </ModalFooter>
        </Modal>
    }
}

export default connect((state, ownProps) => ({
    member: state.modal.getIn([ownProps.modalName, 'member']),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    deleteMember: (member, tableName) => (dispatch({type: DELETE_MEMBER, member: member, modalName: ownProps.modalName, tableName: ownProps.tableName})),
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName))
}))(DeleteMemberModal);