import {SET_PASSWORD, USER_BY_ACTIVATION_CODE} from "../actions";
import {LOGIN_PAGE} from "../types";

export const showLogin = () => ({type: LOGIN_PAGE}),
    getActivationCodeCreator = (activationCode) => ({type: USER_BY_ACTIVATION_CODE, activationCode: activationCode}),
    registerCreator = (activationCode, password, passwordRepeat, setSubmitting) => ({
        type: SET_PASSWORD,
        password: password,
        passwordRepeat: passwordRepeat,
        activationCode: activationCode,
        setSubmitting: setSubmitting
    });