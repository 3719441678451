import React from "react";
import {connect} from "react-redux";
import AdminHeader from "../../components/Headers/AdminHeader";
import {Card, CardHeader, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";
import ServerSideTable from "../../components/ServerSideTable";
import {List, Map} from "immutable";
import {showModalCreator} from "../../reducers/modalReducer";
import EditUserModal from "./EditUserModal";
import SendCodeModal from "./SendCodeModal";
import DeleteUserModal from "./DeleteUserModal";
import {copy} from "redux-clipboard-copy";

class Users extends React.Component {

    constructor(props) {
        super(props);

        this.editUserModalName = 'EditUserModal';
        this.userTableName = 'UsersTable';
        this.sendCodeModalName = 'SendCodeModal';
        this.deleteUserModalName = 'DeleteUserModal';
    }

    render() {
        const {users, showEditUserModal, showSendCodeModal, showDeleteUserModal, copyActivationCodeToClipboard} = this.props;

        return (
            <>
                <AdminHeader/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0 float-left">Beitragsgrupppen</h3>
                                </CardHeader>
                                <ServerSideTable columns={List([
                                    Map([
                                        ['key', 'lastname'],
                                        ['display', 'Nachname']
                                    ]),
                                    Map([
                                        ['key', 'firstname'],
                                        ['display', 'Vorname']
                                    ]),
                                    Map([
                                        ['key', 'username'],
                                        ['display', 'Benutzername']
                                    ]),
                                    Map([
                                       ['key', 'roles'],
                                        ['display', 'Rollen']
                                    ]),
                                    Map([
                                        ['key', ''],
                                        ['display', ''],
                                        ['sortable', false],
                                        ['valueConverter', (val, fullData) => <UncontrolledDropdown>
                                            <DropdownToggle
                                                className="btn-icon-only text-light"
                                                href="#"
                                                role="button"
                                                size="sm"
                                                color=""
                                                onClick={e => e.preventDefault()}
                                            >
                                                <i className="fas fa-ellipsis-v"/>
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        showEditUserModal(this.editUserModalName, Map([['user', fullData], ['userTableName', this.userTableName]]));
                                                    }}
                                                >
                                                    <i className="fas fa-pencil-alt"></i>
                                                    Editieren
                                                </DropdownItem>
                                                <DropdownItem
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        showSendCodeModal(this.sendCodeModalName, Map([['user', fullData]]))
                                                    }}
                                                >
                                                    <i className="far fa-envelope"></i>
                                                    E-Mail senden
                                                </DropdownItem>
                                                <DropdownItem
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        copyActivationCodeToClipboard(window.location.origin + "/register/" + fullData.get('activationCode'));
                                                    }}
                                                >
                                                    <i className="far fa-clipboard"></i>
                                                    In die Zwischenablage kopieren
                                                </DropdownItem>
                                                <DropdownItem
                                                    href="#"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        showDeleteUserModal(this.deleteUserModalName, Map([['user', fullData]]))
                                                    }}
                                                >
                                                    <i className="far fa-trash-alt"></i>
                                                    Löschen
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>]
                                    ])])} values={users} isLocal={true} tableName={this.userTableName}
                                                 textSearchProps={List(['name'])}></ServerSideTable>
                            </Card>
                        </div>
                    </Row>

                    <EditUserModal modalName={this.editUserModalName}/>
                    <SendCodeModal modalName={this.sendCodeModalName}/>
                    <DeleteUserModal modalName={this.deleteUserModalName}/>
                </Container>
            </>);
    }
}

export default connect(state => ({
        users: state.sua.get('users')
    }),
    dispatch => ({
        showEditUserModal: (showEditUserModalName, props) => dispatch(showModalCreator(showEditUserModalName, props)),
        showSendCodeModal: (showSendCodeModalName, props) => dispatch(showModalCreator(showSendCodeModalName, props)),
        showDeleteUserModal: (showDeleteUserModalName, props) => dispatch(showModalCreator(showDeleteUserModalName, props)),
        copyActivationCodeToClipboard: (activationUrl) => dispatch(copy({payload: {value: activationUrl}}))
    })
)(Users);