import Api from "../api/Api";
import {call, put} from "redux-saga/effects";
import {ADDED_SEASON} from "../actions";
import {error, success} from "react-notification-system-redux";

export function* seasonsAdd(action) {
    try {
        const newSeason = yield call(Api.addSeason);
        yield put({type: ADDED_SEASON, newSeason: newSeason});
        yield put(success({
            title: "Neue Saison hinzugefügt",
            message: "Die neue Saison wurde erfolgreich hinzugefügt. Sie befinden sich nun in der Saison " + newSeason.season + "!"
        }));
    } catch (seasonError) {
        yield put(error({
            title: "Wechsel in die neue Saison fehlgeschlagen",
            message: "Es ist beim Wechseln in die neue Saison ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
        }));
    }

    action.setSubmitting(false);
}