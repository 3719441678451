import {connect} from "react-redux";
import * as React from "react";
import {Col, Container, Row} from "reactstrap";

class NotFound extends React.Component {
    render() {
        return <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
            <Container fluid>
                <div className="header-body">
                    {/* Card stats */}
                    <Row>
                        <Col lg="6" xl="3">
                            Die angeforderte Seite konnte nicht gefunden werden!
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    }
}

export default connect(state => ({}))(NotFound);