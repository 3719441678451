import React from "react";
import {connect} from "react-redux";
import {Button, ModalFooter, ModalBody, Modal, ModalHeader} from "reactstrap";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";

class NewCoachInfoModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {activationUrl, backdrop, isOpen, hideModal} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Aktivierungslink für neuen Coach</ModalHeader>
            <ModalBody>
                Bitte geben Sie den folgenden Link an den neuen Kursleiter weiter:

                <div className="activationLink">
                    {activationUrl}
                </div>

                Der Kursleiter benötigt diesen Link für die erstmalige Anmeldung. Bitte beachten Sie dabei dass dieser
                Link
                lediglich 24 Stunden gültig ist. Sollte die Gültigkeit des Links ablaufen können Sie jedoch jederzeit in
                der
                Benutzerverwaltung einen neuen Link generieren der wieder 24 Stunden gültig ist.
            </ModalBody>
            <ModalFooter>
                <Button type="button" color='secondary' onClick={() => hideModal()}>OK</Button>
            </ModalFooter>
        </Modal>
    }
}

NewCoachInfoModal = connect((state, ownProps) => ({
    activationUrl: state.modal.getIn([ownProps.modalName, 'activationUrl']),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName))
}))(NewCoachInfoModal);

export default NewCoachInfoModal;