import React from "react";

import {connect} from "react-redux";
import {
    CardFooter,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table
} from "reactstrap";
import {
    changePageCreator,
    changeSortCreator,
    clearErrorsCreator,
    clearSearchCreator,
    fetchDataThunk,
    initNewTableCreator,
    searchCreator
} from "../reducers/tableReducer";
import {error} from "react-notification-system-redux";
import {ASCENDING} from "../constants";
import {List} from "immutable";

class ServerSideTable extends React.Component {

    constructor(props) {
        super(props);

        this.columns = props.columns;
        this.api = props.api;
        this.tableName = props.tableName;
        this.sort = props.sort || 0;
        this.data = props.values || List();
        this.isLocal = props.isLocal;
        this.pageSize = props.pageSize || 50;
        this.textSearchProps = props.textSearchProps;
        this.sortOrder = props.sortOrder || ASCENDING;
        this.navClassNames = props.navClassNames || "";
        this.disableReinit = props.disableReinit;

        this.props.initTable({
            api: this.api,
            sort: this.sort,
            columns: this.columns,
            data: this.data,
            isLocal: this.isLocal,
            pageSize: this.pageSize,
            textSearchProps: this.textSearchProps,
            sortOrder: this.sortOrder,
            disableReinit: this.disableReinit
        });
        if (!this.isLocal) {
            this.props._fetchData();
        }
    }

    componentDidUpdate(prevProps) {

        const {values, initTable} = this.props;

        console.log("componentDidUpdate called", prevProps.values, values);

        if (prevProps.values !== values && values) {
            console.log("valuesChanged", values);

            this.data = values;

            initTable({
                api: this.api,
                sort: this.sort,
                columns: this.columns,
                data: this.data,
                isLocal: this.isLocal,
                pageSize: this.pageSize,
                textSearchProps: this.textSearchProps,
                sortOrder: this.sortOrder
            });
        }

    }

    renderTableHeaders() {
        const {sort, sortOrder, changeSort} = this.props;

        return this.columns.map((valueMap, idx) => (
            valueMap.get('rendered') === false ? <></> :
                <th key={valueMap.get('key')} scope="col"
                    onClick={() => valueMap.get('sortable') !== false ? changeSort(idx) : ''}>
                    {valueMap.get('display')}
                    {valueMap.get('sortable') !== false ?
                        <i className={sort === idx ? (sortOrder === ASCENDING ? 'fas fa-sort-up' : 'fas fa-sort-down') : 'fas fa-sort'}></i> : ''}
                </th>));
    }

    renderColumn(column) {
        return this.columns.map((valueMap, idx) => (
            <td key={valueMap.get('key') + idx} className={valueMap.get('classes', '')}>
                {valueMap.get('valueConverter') && typeof valueMap.get('valueConverter') === "function" ?
                    valueMap.get('valueConverter')(column.get(valueMap.get('key')), column) : column.get(valueMap.get('key'))}
            </td>));
    }

    renderPaginationElements() {
        const {pageSize, filteredCount, page, changePage} = this.props;

        const renderList = [];
        for (let i = Math.max(page - 2, 1) - Math.max(2 - page - Math.ceil(filteredCount / pageSize), 0); i < page; i++) {
            renderList.push(<PaginationItem key={'page' + i}>
                <PaginationLink href="#" onClick={e => {
                    e.preventDefault();
                    changePage(i)
                }}>
                    {i}
                </PaginationLink>
            </PaginationItem>)
        }
        renderList.push(<PaginationItem key={'page' + page} className='active'>
            <PaginationLink href="#" onClick={e => e.preventDefault()}>
                {page} <span className="sr-only">(current)</span>
            </PaginationLink>
        </PaginationItem>);
        for (let i = page + 1; i < Math.min(page + 3 + Math.max(3 - page, 0), Math.ceil(filteredCount / pageSize) + 1); i++) {
            renderList.push(<PaginationItem key={'page' + i}>
                <PaginationLink href="#" onClick={e => {
                    e.preventDefault();
                    changePage(i)
                }}>
                    {i}
                </PaginationLink>
            </PaginationItem>)
        }

        return renderList;
    }


    render() {
        const {data, error, filteredCount, totalCount, pageSize, showError, page, changePage, search, clearSearch, searchText} = this.props;
        const isFirstDisabled = page === 1;
        const isLastDisabled = page === Math.ceil(filteredCount / pageSize);

        if (error) {
            showError(error);
        }

        return <>
            <Form className="float-right navbar-search form-inline mr-3 d-none ml-lg-auto d-md-inline-block mb-4">
                <FormGroup className="mb-0">
                    <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="fas fa-search"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Suche" type="text"
                               value={searchText}
                               onChange={(e) => e.target.value.length > 2 ? search(e.target.value) : (searchText ? clearSearch() : console.log())}/>
                    </InputGroup>
                </FormGroup>
            </Form>
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                    {this.renderTableHeaders()}
                </tr>
                </thead>
                <tbody>
                {data ? data.map((entry, idx) => {
                    return <tr key={'row' + idx}>
                        {this.renderColumn(entry)}
                    </tr>
                }) : <tr>
                    <td colSpan={this.columns.size}>Keine Daten vorhanden</td>
                </tr>
                }
                </tbody>
            </Table>

            <CardFooter className="py-4">
                <div className="float-left">
                    Es werden {data ? data.size : 0} von {filteredCount} Einträgen
                    angezeigt {filteredCount !== totalCount ? `(gefiltert von ${totalCount})` : ''}
                </div>
                <nav aria-label="..." className={`${this.navClassNames} float-right`}>
                    {filteredCount > pageSize ?
                        <Pagination className="pagination justify-content-end mb-0"
                                    listClassName="justify-content-end mb-0">
                            <PaginationItem className={isFirstDisabled ? 'disabled' : ''}>
                                <PaginationLink href="#" onClick={e => {
                                    e.preventDefault();
                                    if (!isFirstDisabled) {
                                        changePage(1);
                                    }
                                }} tabIndex="-1">
                                    <i className="fas fa-angle-left"/>
                                    <i className="fas fa-angle-left"/>
                                    <span className="sr-only">First</span>
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem className={isFirstDisabled ? 'disabled' : ''}>
                                <PaginationLink href="#" onClick={e => {
                                    e.preventDefault();
                                    if (!isFirstDisabled) {
                                        changePage(page - 1)
                                    }
                                }} tabIndex="-1">
                                    <i className="fas fa-angle-left"/>
                                    <span className="sr-only">Previous</span>
                                </PaginationLink>
                            </PaginationItem>
                            {this.renderPaginationElements()}
                            <PaginationItem className={isLastDisabled ? 'disabled' : ''}>
                                <PaginationLink href="#" onClick={e => {
                                    e.preventDefault();
                                    if (!isLastDisabled) {
                                        changePage(page + 1);
                                    }
                                }}>
                                    <i className="fas fa-angle-right"/>
                                    <span className="sr-only">Next</span>
                                </PaginationLink>
                            </PaginationItem>
                            <PaginationItem className={isLastDisabled ? 'disabled' : ''}>
                                <PaginationLink href="#" onClick={e => {
                                    e.preventDefault();
                                    if (!isLastDisabled) {
                                        changePage(Math.ceil(filteredCount / pageSize));
                                    }
                                }}>
                                    <i className="fas fa-angle-right"/>
                                    <i className="fas fa-angle-right"/>
                                    <span className="sr-only">Last</span>
                                </PaginationLink>
                            </PaginationItem>
                        </Pagination> : ''
                    }
                </nav>
            </CardFooter>
        </>;
    }
}

export default connect((state, ownProps) => ({
    data: state.table.getIn([ownProps.tableName, 'data']),
    error: state.table.getIn([ownProps.tableName, 'error']),
    totalCount: state.table.getIn([ownProps.tableName, 'totalCount']),
    filteredCount: state.table.getIn([ownProps.tableName, 'filteredCount']),
    pageSize: state.table.getIn([ownProps.tableName, 'pageSize']) || ownProps.pageSize,
    page: state.table.getIn([ownProps.tableName, 'page']),
    sort: state.table.getIn([ownProps.tableName, 'sort']) === 0 ? 0 : (state.table.getIn([ownProps.tableName, 'sort']) || ownProps.sort),
    sortOrder: state.table.getIn([ownProps.tableName, 'sortOrder']) || ownProps.sortOrder,
    searchText: state.table.getIn([ownProps.tableName, 'searchText'])
}), (dispatch, ownProps) => ({
    initTable: (initialState) => dispatch(initNewTableCreator(ownProps.tableName, initialState)),
    _fetchData: () => dispatch(fetchDataThunk(ownProps.tableName)),
    showError: (errorMsg) => {
        dispatch(error({
            title: 'Fehler beim Abrufen der Daten',
            message: errorMsg
        }));
        dispatch(clearErrorsCreator(ownProps.tableName));
    },
    changePage: (next) => {
        dispatch(changePageCreator(ownProps.tableName, next));
        if (!ownProps.isLocal) {
            dispatch(fetchDataThunk(ownProps.tableName));

        }
    },
    changeSort: (sort) => {
        dispatch(changeSortCreator(ownProps.tableName, sort));
        if (!ownProps.isLocal) {
            dispatch(fetchDataThunk(ownProps.tableName));
        }
    },
    search: (search) => {
        dispatch(searchCreator(ownProps.tableName, search));
        if (!ownProps.isLocal) {
            dispatch(fetchDataThunk(ownProps.tableName));
        }
    },
    clearSearch: () => {
        dispatch(clearSearchCreator(ownProps.tableName));
        if (!ownProps.isLocal) {
            dispatch(fetchDataThunk(ownProps.tableName));
        }
    }
}))(ServerSideTable);