/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Button,
    Label,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Media,
    Nav,
    Navbar,
    UncontrolledDropdown
} from "reactstrap";
import {connect} from "react-redux";
import {ADD_SEASON, CHANGE_SEASON, LOGOUT} from "../../actions";
import {Field, Formik, Form as FormikForm} from "formik";
import {customReactSelect} from "../../customComponents";
import {redirect} from "redux-first-router";
import {LOGIN_PAGE} from "../../types";

class AdminNavbar extends React.Component {

    constructor(props) {
        super(props);
    }

    getSeasonsOptions() {
        const {seasons} = this.props;

        return seasons ? seasons.map(season => ({
            value: season.get("season"),
            label: `${season.get('season') - 1}/${season.get("season")}`,
        })) : {};
    }

    render() {
        const {user, activeSeason, changeSeason, addSeason, logout} = this.props;

        return (
            <>
                <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
                    <Container fluid>
                        <Formik enableReinitialize
                                initialValues={{
                                    season: activeSeason ? {
                                        label: `${activeSeason.get('season') - 1}/${activeSeason.get('season')}`,
                                        value: activeSeason.get('season')
                                    } : ''
                                }}
                                onSubmit={(values, {setSubmitting}) => {
                                    addSeason(setSubmitting);
                                    setSubmitting(true);
                                }}>
                            {({isSubmitting}) => (
                                <FormikForm className='d-flex align-items-start'>
                                    <FormGroup className='d-flex align-items-start mr-4'>
                                        <Label for='season' sm={5}
                                               className="h4 mb-0 text-white text-uppercase d-flex mr-4">
                                            Saison
                                        </Label>
                                        <Field id='season' name='season' onChange={val => changeSeason(val.value)}
                                               sm={5} className='d-flex'
                                               autosize={false}
                                               component={customReactSelect}
                                               options={this.getSeasonsOptions()}/>
                                    </FormGroup>
                                    <FormGroup className='d-flex align-items-start'>
                                        <Button disabled={isSubmitting} className="btn-circle add-season-btn d-none"
                                                color='primary' type='submit'>
                                            <i className="fa fa-plus"></i>
                                        </Button>
                                    </FormGroup>
                                </FormikForm>
                            )}
                        </Formik>
                        {/*<Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                            <FormGroup className="mb-0">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-search"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Search" type="text"/>
                                </InputGroup>
                            </FormGroup>
                        </Form>*/}
                        <Nav className="align-items-center d-none d-md-flex" navbar>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="pr-0" nav>
                                    <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                        
                      <img
                          alt="..."
                          src={require("assets/img/icons/user-silhouette.svg")}
                      />
                    </span>
                                        <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {user ? (user.get('firstname') + ' ' + user.get('lastname')) : ''}
                      </span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem className="noti-title" header tag="div">
                                        <h6 className="text-overflow m-0">Willkommen!</h6>
                                    </DropdownItem>
                                    {/*<DropdownItem to="/admin/user-profile" tag={Container}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Container}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Container}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/user-profile" tag={Container}>
                    <i className="ni ni-support-16" />
                    <span>Support</span>
                  </DropdownItem>
                  <DropdownItem divider />*/}
                                    <DropdownItem onClick={() => logout()}>
                                        <i className="ni ni-user-run"/>
                                        <span>Abmelden</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Container>
                </Navbar>
            </>
        );
    }
}

export default connect(
    (state) => ({
        seasons: state.sua.get("seasons"),
        user: state.sua.get('user'),
        activeSeason: state.sua.get("activeSeason")
    }), (dispatch) => ({
        changeSeason: (season) => (dispatch({type: CHANGE_SEASON, season: season})),
        addSeason: (setSubmitting) => (dispatch({type: ADD_SEASON, setSubmitting: setSubmitting})),
        logout: () => {
            dispatch({type: LOGOUT});
            dispatch(redirect({type: LOGIN_PAGE}));
        }
    })
)(AdminNavbar);
