import React from "react";
import {connect} from "react-redux";
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ServerSideTable from "../../components/ServerSideTable";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";
import {ADD_COURSES} from "../../actions";
import {List, Map} from "immutable";
import {addFilterCreator, removeFilterCreator} from "../../reducers/tableReducer";

const ONLY_SELECTED_FILTER = "ONLY_SELECTED_FILTER";

class AddCourseModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
        this.state = {
            courses: List()
        }
    }

    handleChange(businessKey, checked) {
        const {member} = this.props;

        if (checked) {

        } else {

        }

        this.setState((state) => {
            return {
                courses: checked ? state.courses.push(businessKey) : state.courses.delete(state.courses.indexOf(businessKey))
            }
        });
    }

    setCoursesState() {
        const {member, activeSeason} = this.props;

        this.setState(state => {
            return {
                courses: member && member.getIn(['courses', activeSeason.get('season').toString()]) ?
                    member.getIn(['courses', activeSeason.get('season').toString()]).map(course => course.get('businessKey')) : List()
            }
        });
    }

    componentDidUpdate(prevProps) {
        const {member} = this.props;

        if (member !== prevProps.member) {
            this.setCoursesState();
        }
    }

    render() {
        const {tableName, filters, addCourses, hideModal, isOpen, backdrop, member, courses, activeSeason, addOnlySelectedFilter, removeOnlySelectedFilter} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Kurse für das
                Mitglied {member ? member.get('firstname') : ''} {member ? member.get('lastname') : ''} bearbeiten</ModalHeader>
            <ModalBody>
                <Button
                    color={filters && filters.get(ONLY_SELECTED_FILTER) ? 'primary' : 'secondary'}
                    className="mb-3"
                    onClick={() => {
                        filters && filters.get(ONLY_SELECTED_FILTER) ? removeOnlySelectedFilter() : addOnlySelectedFilter(this.state.courses);
                    }}>Nur Selektierte</Button>
                <ServerSideTable columns={List([
                    Map([
                        ['key', 'name'],
                        ['display', 'Kursbezeichnung']
                    ]),
                    Map([
                        ['key', 'businessKey'],
                        ['display', ''],
                        ['sortable', false],
                        ['valueConverter', (val) => <Input type='checkbox'
                                                           onChange={(event) => this.handleChange(val, event.target.checked)}
                                                           checked={this.state.courses.indexOf(val) !== -1}/>]
                    ])
                ])} values={courses} isLocal={true} tableName={tableName} pageSize={10} textSearchProps={List(['name'])} navClassNames="mt-5">

                </ServerSideTable>
                <ModalFooter>
                    <Button type="button" color="secondary" onClick={hideModal}>Abbrechen</Button>
                    <Button type="submit" className="primary"
                            onClick={() => addCourses(member.get('businessKey'), this.state.courses)}>Kurse
                        speichern
                    </Button>
                </ModalFooter>
                {/* /.modal-content */}
            </ModalBody>
            {/* /.modal-dialog */}
        </Modal>
    }
}

export default connect((state, ownProps) => ({
    activeSeason: state.sua.get("activeSeason"),
    courses: state.sua.get("courses"),
    member: state.modal.getIn([ownProps.modalName, 'member']),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop']),
    filters: state.table.getIn([ownProps.tableName, 'filters'])
}), (dispatch, ownProps) => ({
    addCourses: (id, courses) => (dispatch({
        type: ADD_COURSES,
        modalName: ownProps.modalName,
        tableName: ownProps.memberTableName,
        courses: courses,
        memberId: id
    })),
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
    addOnlySelectedFilter: (courses) => dispatch(addFilterCreator(ownProps.tableName, ONLY_SELECTED_FILTER, (tableEntry) =>
        courses.indexOf(tableEntry.get('businessKey')) !== -1
    )),
    removeOnlySelectedFilter: () => dispatch(removeFilterCreator(ownProps.tableName, ONLY_SELECTED_FILTER))
}))(AddCourseModal)
