import {FormFeedback, Input} from "reactstrap";
import AsyncSelect from 'react-select/async';
import Api from "./api/Api";
import React from "react";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

export const customInputForm = ({field, form: {touched, errors}, ...props}) => (
        <> <Input
            invalid={!!(touched[field.name] && errors[field.name])}
            {...field}
            {...props} />
            {errors[field.name] && touched[field.name] ? (
                <FormFeedback>{errors[field.name]}</FormFeedback>
            ) : null}
        </>
    ),

    customInputRadio = ({field, form: {touched, errors, values}, ...props}) => (
        <> <Input
            invalid={!!(touched[field.name] && errors[field.name])}
            checked={props.value === values.paymentType}
            {...field}
            {...props}
            type='radio'/>
        </>
    ),

    customReactSelect = ({field, form: {touched, errors, values, setFieldValue, setFieldTouched}, ...props}) => {

        field.onChange = (selectedOptions) => setFieldValue(field.name, selectedOptions);

        return <><Select
            invalid={!!(touched[field.name] && errors[field.name])}
            onBlur={() => setFieldTouched(field.name, true)}
            value={field.value}
            {...field}
            {...props}
        />
            {errors[field.name] && touched[field.name] ? (
                <FormFeedback className='d-block'>{errors[field.name]}</FormFeedback>
            ) : null}</>
    },

    customReactCreatableSelect = ({field, form: {touched, errors, values, setFieldValue, setFieldTouched}, ...props}) => {

        field.onChange = (selectedOptions) => setFieldValue(field.name, selectedOptions);

        return <><CreatableSelect
            invalid={!!(touched[field.name] && errors[field.name])}
            onBlur={() => setFieldTouched(field.name, true)}
            value={field.value}
            {...field}
            {...props}
        />
            {console.log(field.name, errors[field.name], touched[field.name])}
            {errors[field.name] && touched[field.name] ? (
                <FormFeedback className='d-block'>{errors[field.name]}</FormFeedback>
            ) : null}</>
    },

    customInputCheckbox = ({field, form: {touched, errors, values}, ...props}) => (
        <> <Input
            invalid={!!(touched[field.name] && errors[field.name])}
            checked={props.value === values.paymentType}
            {...field}
            {...props}
            type='checkbox'/>
            {errors[field.name] && touched[field.name] ? (
                <FormFeedback>{errors[field.name]}</FormFeedback>
            ) : null}
        </>
    ),

    customAsyncReactSelect = ({field, form: {errors, touched, setFieldValue}, ...props}) => {

        //const animatedComponents = makeAnimated();

        field.onChange = (option) => setFieldValue(field.name, option);

        return <><AsyncSelect
            //components={animatedComponents}
            cacheOptions
            defaultOptions
            loadOptions={Api.searchMembers}
            minLength={3}
            {...field}
            {...props}
        />
            {errors[field.name] && touched[field.name] ? (
                <FormFeedback>{errors[field.name]}</FormFeedback>
            ) : null}</>
    };