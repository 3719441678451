import fetch from "cross-fetch";
import {createMemberUrl, createSetCoursesUrl, restMap} from "../routesMap";
import {
    COURSE_CATEGORIES,
    COURSES_CREATE,
    COURSES_LIST, COURSES_LIST_OWNER, COURSES_LIST_OWNER_ID, CURRENT_USER,
    DASHBOARD,
    MEMBERS_CREATE,
    MEMBERS_DETAILS,
    MEMBERS_FOR_SEASON,
    MEMBERS_LIST,
    PAYMENT_TYPES_LIST,
    SEASONS_ADD,
    SEASONS_LIST,
    USERS_LIST
} from "../types";
import {
    ADD_MEMBER_TO_CURRENT_SEASON,
    ADD_PAYMENT,
    CREATE_OR_UPDATE_PAYMENT_TYPE,
    DELETE_MEMBER_FROM_CURRENT_SEASON,
    DELETE_PAYMENT_TYPE,
    DELETE_USER,
    DISABLE_USER,
    ENABLE_USER,
    LOGIN,
    LOGOUT,
    MEMBER_EXISTS,
    MEMBER_SEARCH,
    REFRESH_TOKEN,
    SEND_CODE,
    SET_PASSWORD,
    UNDO_PAYMENT,
    UPDATE_FAMILY_PAYMENTS,
    UPDATE_USER,
    USER_BY_ACTIVATION_CODE
} from "../actions";
import {fromJS} from "immutable";
import {APPLICATION_JSON, TEXT_PLAIN} from "../constants";

const retry = (response, request, resolver, reject) => {
    if (response.status === 401) {
        // access token invalid, refresh token not
        localStorage.removeItem('jwtAccessToken');

        if (localStorage.getItem('jwtRefreshToken')) {
            Api.refreshToken().then((jwtResponse) => {
                localStorage.setItem('jwtAccessToken', jwtResponse.jwtAccessToken);
                localStorage.setItem('jwtRefreshToken', jwtResponse.jwtRefreshToken);

                request().then(resolver).catch(function (error) {
                    localStorage.removeItem('jwtAccessToken');
                    localStorage.removeItem('jwtRefreshToken');
                    reject('jwt_invalid');
                });
            }, (error) => {
                localStorage.removeItem('jwtAccessToken');
                localStorage.removeItem('jwtRefreshToken');
                reject('jwt_invalid');
            });
        }
    }
}

const fetchEntities = (url, toObject = true, customResolver) => {

    let shouldRetry = true;
    return new Promise((resolve, reject) => {
        const fetchEntitiesRequest = () => fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwtAccessToken')
            },
        });

        const resolver = (response) => {
            if (response.ok) {
                response.json().then(function (entities) {
                    if (toObject === true) {
                        //resolve(Util.arrayToObject(entities, "businessKey"));
                        resolve(entities);
                    } else {
                        resolve(entities);
                    }
                });
            } else {
                if (shouldRetry === true) {
                    shouldRetry = false;
                    retry(response, fetchEntitiesRequest, resolver, reject);
                }
            }
        };

        fetchEntitiesRequest().then(customResolver ? customResolver : resolver).catch(function (error) {
            reject(error);
        });
    });
};

const postRequest = (url, data = {}, contentType = APPLICATION_JSON, parse = true) => {
    let shouldRetry = true;
    return new Promise((resolve, reject) => {
        const headers = {
            'content-type': contentType
        };
        if (!data.refreshToken) {
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwtAccessToken');
        }

        const postRequest = () => fetch(url, {
            body: contentType === APPLICATION_JSON ? JSON.stringify(data) : data, // must match 'Content-Type' header
            cache: 'no-cache', // *default, cache, reload, force-cache, only-if-cached
            headers: headers,
            method: 'POST' // *GET, PUT, DELETE, etc.
        });

        const resolver = (response) => {
            if (response.ok) {
                if (parse === true) {
                    response.json().then(function (responseJson) {
                        resolve(responseJson);
                    });
                } else {
                    const location = response.headers.get('location');
                    if (location) {
                        const lastIndexOfSlash = location.lastIndexOf('/');

                        if (lastIndexOfSlash !== -1) {
                            const businessKey = parseInt(location.substring(lastIndexOfSlash + 1), 10);
                            if (isNaN(businessKey)) {
                                resolve();
                            } else {
                                resolve(businessKey);
                            }
                        } else {
                            resolve();
                        }
                    } else {
                        resolve();
                    }
                }
            } else {
                if (url !== restMap[REFRESH_TOKEN] && shouldRetry === true) {
                    shouldRetry = false;
                    retry(response, retry, postRequest, resolver, reject);
                } else {
                    reject('jwt_invalid');
                }
            }
        };
        postRequest().then(resolver).catch(function (error) {
            reject(error);
        });
    });
};

const putRequest = (url, id, data = {}, readJson = true, useBearer = true) => {
    let shouldRetry = true;
    return new Promise((resolve, reject) => {
        const headers = {'content-type': 'application/json'};
        if (useBearer) {
            headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwtAccessToken');
        }

        const putRequest = () => fetch(url + (id ? "/" + id : ""), {
            body: JSON.stringify(data), // must match 'Content-Type' header
            cache: 'no-cache', // *default, cache, reload, force-cache, only-if-cached
            headers: headers,
            method: 'PUT' // *GET, PUT, DELETE, etc.
        });

        const resolver = (response) => {
            if (response.ok) {
                if (readJson === true) {
                    response.json().then(function (responseJson) {
                        resolve(responseJson ? responseJson : {});
                    });
                } else {
                    response.text().then(function (text) {
                        resolve(text ? text : {});
                    });
                }
            } else {
                if (shouldRetry === true) {
                    shouldRetry = false;
                    retry(response, putRequest, resolve, reject);
                } else {
                    reject('jwt_invalid');
                }
            }
        };

        putRequest().then(resolver).catch(function (error) {
            reject(error);
        });
    });
};

const deleteRequest = (url, id, data = {}) => {
    let shouldRetry = true;
    return new Promise((resolve, reject) => {
        const deleteRequest = () => fetch(url + (id === null ? "" : "/" + id), {
            body: JSON.stringify(data), // must match 'Content-Type' header
            cache: 'no-cache', // *default, cache, reload, force-cache, only-if-cached
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtAccessToken')
            },
            method: 'DELETE' // *GET, PUT, DELETE, etc.
        });

        const resolver = (response) => {
            if (response.ok) {
                resolve();
            } else {
                if (shouldRetry === true) {
                    shouldRetry = false;
                    retry(response, deleteRequest, resolver, reject);
                } else {
                    reject('jwt_invalid');
                }
            }
        };

        deleteRequest().then(resolver).catch(function (error) {
            reject(error);
        });
    });
};

class Api {

    constructor() {
        this.standardParams = {
            cache: 'no-cache',
            headers: {
                'content-type': 'application/json'
            }
        };
    }

    static fetchTableData(api, sort, sortOrder, page, pageSize, search, params) {
        let shouldRetry = true;

        const tableDataRequest = () => fetch(`${api}?sort=${sort}&sortOrder=${sortOrder}&page=${page}&pageSize=${pageSize}${search}${params}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwtAccessToken')
            }
        });

        return new Promise((resolve, reject) => {
            const resolver = (response) => {
                if (response.ok) {
                    response.json().then(function (responseJson) {
                        resolve(responseJson);
                    });
                } else {
                    if (shouldRetry === true) {
                        shouldRetry = false;
                        retry(response, tableDataRequest, resolver, reject);
                    } else {
                        reject('jwt_invalid');
                    }
                }
            }

            tableDataRequest().then(resolver).catch(function (error) {
                reject(error);
            });
        });
    }

    static refreshToken() {
        return postRequest(restMap[REFRESH_TOKEN], {
            refreshToken: localStorage.getItem('jwtRefreshToken')
        });
    }

    static addSeason() {
        return postRequest(restMap[SEASONS_ADD]);
    }

    static fetchPaymentTypes() {
        return fetchEntities(restMap[PAYMENT_TYPES_LIST]);
    }

    static fetchUsers() {
        return fetchEntities(restMap[USERS_LIST]);
    }

    static fetchMembers(activeSeason) {
        if (activeSeason) {
            return fetchEntities(restMap[MEMBERS_FOR_SEASON](activeSeason));
        } else {
            return fetchEntities(restMap[MEMBERS_LIST]);
        }
    }

    static getDashboardData(season) {
        return fetchEntities(restMap[DASHBOARD](season), false);
    }

    static fetchCourses() {
        return fetchEntities(restMap[COURSES_LIST]);
    }

    static fetchCoursesForOwner() {
        return fetchEntities(restMap[COURSES_LIST_OWNER]);
    }

    static fetchCoursesForCoach(id) {
        return fetchEntities(restMap[COURSES_LIST_OWNER_ID](id), false);
    }

    static fetchSeasons() {
        return fetchEntities(restMap[SEASONS_LIST], false);
    }

    static fetchCurrentUser() {
        return fetchEntities(restMap[CURRENT_USER], false);
    }

    static addCourses(id, courses) {
        return putRequest(createSetCoursesUrl(id), null, courses, false);
    }

    static deletePaymentType(id) {
        return deleteRequest(restMap[DELETE_PAYMENT_TYPE], id);
    }

    static deleteUser(id) {
        return deleteRequest(restMap[DELETE_USER], id);
    }

    static disableUser(id) {
        return putRequest(restMap[DISABLE_USER](id), null, {}, false);
    }

    static enableUser(id) {
        return putRequest(restMap[ENABLE_USER](id), null, {}, false);
    }

    static addPaymentType(paymentType) {
        return postRequest(restMap[CREATE_OR_UPDATE_PAYMENT_TYPE](), paymentType);
    }

    static addPayment(id, year, amount) {
        return putRequest(restMap[ADD_PAYMENT](id, year, amount), null, {}, true);
    }

    static existsMember(firstname, lastname) {
        return fetchEntities(restMap[MEMBER_EXISTS](firstname, lastname), false);
    }

    static undoPayment(id, year) {
        return putRequest(restMap[UNDO_PAYMENT](id, year), null, {}, false);
    }

    static updateFamilyPayments() {
        return fetchEntities(restMap[UPDATE_FAMILY_PAYMENTS], false);
    }

    static updatePaymentType(paymentType) {
        return putRequest(restMap[CREATE_OR_UPDATE_PAYMENT_TYPE](paymentType.get('businessKey')), null, paymentType);
    }

    static updateUser(user) {
        return putRequest(restMap[UPDATE_USER](user.businessKey), null, {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            sex: user.sex
        }, false);
    }

    static createMember(member) {
        return postRequest(restMap[MEMBERS_CREATE], member);
    }

    static createCourse(course) {
        return postRequest(restMap[COURSES_CREATE], course);
    }

    static createCourseCategory(name) {
        return postRequest(restMap[COURSE_CATEGORIES], name, TEXT_PLAIN, false);
    }

    static deleteCourse(id) {
        return deleteRequest(restMap[COURSES_CREATE], id);
    }

    static updateCourse(id, course) {
        return putRequest(restMap[COURSES_CREATE], id, course);
    }

    static updateMember(member, id) {
        return putRequest(restMap[MEMBERS_CREATE], id, member, false);
    }

    static fetchMember(id) {
        return fetchEntities(restMap[MEMBERS_DETAILS](id), false);
    }

    static logout() {
        return fetchEntities(restMap[LOGOUT], false);
    }

    static login(username, password) {
        return postRequest(restMap[LOGIN], {username: username, password: password});
    }

    static sendCode(name, activationUrl, email, sex) {
        return putRequest(restMap[SEND_CODE], null, {
            name: name,
            activationUrl: activationUrl,
            sex: sex,
            emailDto: {recipientAddress: email}
        }, false);
    }

    static addMemberToCurrentSeason(memberId) {
        return putRequest(restMap[ADD_MEMBER_TO_CURRENT_SEASON](memberId), null, {}, false);
    }

    static removeMemberFromCurrentSeason(memberId) {
        return putRequest(restMap[DELETE_MEMBER_FROM_CURRENT_SEASON](memberId), null, {}, false);
    }

    static deleteMember(memberId) {
        return deleteRequest(createMemberUrl(memberId), null, {id: memberId}, false);
    }

    static searchMembers(searchString) {
        if (searchString && searchString.length >= 3) {
            return new Promise((resolve, reject) => {
                fetchEntities(restMap[MEMBER_SEARCH](searchString), false, (response) => {
                    if (response.ok) {
                        response.json().then((jsonBody) => {
                            resolve(fromJS(jsonBody).map(member => ({
                                label: `${member.get('firstname')} ${member.get('lastname')}`,
                                value: member.get('businessKey')
                            })));
                        }, (error) => {
                            reject(error);
                        });
                    } else {
                        reject();
                    }
                });
            });

            /*return new Promise((resolve, reject) => {
                fetch(restMap[MEMBER_SEARCH](searchString), {
                    cache: 'no-cache',
                    method: 'GET',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwtAccessToken')
                }).then(function (response) {
                    if (response.ok) {
                        response.json().then((jsonBody) => {
                            resolve(fromJS(jsonBody).map(member => ({
                                label: `${member.get('firstname')} ${member.get('lastname')}`,
                                value: member.get('businessKey')
                            })));
                        }, (error) => {
                            reject(error);
                        });
                    } else {
                        reject();
                    }
                });
            });*/
        } else {
            return Promise.resolve();
        }
    }

    static fetchCoaches() {
        return fetchEntities(restMap[USERS_LIST], false);
    }

    static fetchCourseCategories() {
        return fetchEntities(restMap[COURSE_CATEGORIES], false);
    }

    static getUserByActivationCode(activationCode) {
        return fetchEntities(restMap[USER_BY_ACTIVATION_CODE](activationCode), false);
    }

    static registerUser(activationCode, password, passwordRepeat) {
        return putRequest(restMap[SET_PASSWORD], null, {
            activationCode: activationCode,
            password: password,
            passwordRepeat: passwordRepeat
        }, false, false);
    }
}

export default Api;