import React from "react";
import {connect} from "react-redux";
import ServerSideTable from "../../components/ServerSideTable";
import {List, Map} from "immutable";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";
import Moment from "react-moment";
import moment from 'moment';

class DashboardUnassignedMembersModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {unassignedMembers, activeSeason, tableName, isOpen, backdrop, hideModal} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop} size='lg'>
            <ModalHeader toggle={hideModal}>Mitglieder, die nicht gezählt werden konnten</ModalHeader>
            <ModalBody>
                <div className='mb-5'>
                Mitglieder die in der Saison {activeSeason ? activeSeason.get('season') - 1 : ''}/{activeSeason ? activeSeason.get('season') : ''} für die Statistik nicht erfasst werden konnten,
                aber dennoch als aktiv markiert wurden.
                </div>
                <ServerSideTable columns={List([
                    Map([
                        ['key', 'lastname'],
                        ['display', 'Nachname']
                    ]), Map([
                        ['key', 'firstname'],
                        ['display', 'Vorname']
                    ]), Map([
                        ['key', 'birthdate'],
                        ['display', 'Geburtsdatum'],
                        ['valueConverter', (val) => val ?
                            <Moment format="DD.MM.YYYY">{val}</Moment> : ""]
                    ]), Map([
                        ['key', 'paymentType'],
                        ['display', 'mögliche Fehler'],
                        ['valueConverter', (paymentType, fullData) => {
                            const possibleErrors = [];
                            if(!fullData.get('birthdate')) {
                                possibleErrors.push('Kein Geburtsdatum');
                            } else if(fullData.get('birthdate') && fullData.get('birthdate') > new Date().getTime()) {
                                possibleErrors.push('Geburtsdatum liegt in der Zukunft');
                            }
                            if(!fullData.get('courses').has(activeSeason.get('season').toString())) {
                                possibleErrors.push('Kein Kurs in der gewählten Saison');
                            }
                            if(!fullData.get('sex')) {
                                possibleErrors.push('Kein Geschlecht angegeben');
                            }

                            return possibleErrors.join(', ');
                        }]
                    ])])} tableName={tableName} values={unassignedMembers} isLocal={true} pageSize={25}>
                </ServerSideTable>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color='primary' onClick={() => hideModal()}>Schließen</Button>
            </ModalFooter>
        </Modal>
    }
}

DashboardUnassignedMembersModal = connect((state, ownProps) => ({
    unassignedMembers: state.modal.getIn([ownProps.modalName, 'unassignedMembers']),
    activeSeason: state.sua.get("activeSeason"),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName))
}))(DashboardUnassignedMembersModal);

export default DashboardUnassignedMembersModal;