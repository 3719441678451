import React from 'react'
import Notifications from 'react-notification-system-redux';
import {connect} from "react-redux";
import LoadingIndicator from "./LoadingIndicator";
import Admin from "../layouts/Admin";
import Auth from "../layouts/Auth";
import Login from "../views/login/Login";
import Register from "../views/register/Register";
import RegisterSuccess from "../views/register/RegisterSuccess";

class App extends React.Component {

    render() {
        const {notifications, Page} = this.props;

        const Layout = Page === Login || Page === Register || Page === RegisterSuccess ? Auth : Admin;

        return <div id={"wrapper"}>
            <Notifications notifications={notifications}/>
            <Layout props={this.props}/>
            <LoadingIndicator/>
        </div>
    }
}

export default connect(
    state => ({
        notifications: state.notifications,
        Page: state.page.get('page')
    })
)(App);