import React from "react";
import {connect} from "react-redux";
import {Field, Form, Formik} from "formik";
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {SEX} from "../../enums";
import {customInputForm, customReactSelect} from "../../customComponents";
import {UPDATE_USER} from "../../actions";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";

class EditUserModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {isOpen, backdrop, hideModal, user, updateUser, userTableName} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Editieren eines Benutzers</ModalHeader>
            <Formik enableReinitialize initialValues={{
                firstname: user ? user.get('firstname') : null,
                lastname: user ? user.get('lastname') : null,
                email: user ? user.get('email') : null,
                sex: user ? (user.get('sex') === SEX.FEMALE.value ? SEX.FEMALE : (user.get('sex') === SEX.MALE.value ? SEX.MALE : null)) : null
            }} onSubmit={(values, actions) => {
                values.businessKey = user.get('businessKey');
                if(values.sex) {
                    values.sex = values.sex.value;
                }
                updateUser(values, userTableName);
            }}>
                {props => (
                    <Form id="add-payment-type-modal-form" onSubmit={props.handleSubmit} role="form">
                        <ModalBody className="modal-body">
                            <FormGroup>
                                <Label for="firstname">Vorname</Label>
                                <Field name="firstname" className="form-control" component={customInputForm} type="text"
                                       placeholder="Vorname..."/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="lastname">Nachname</Label>
                                <Field name="lastname" component={customInputForm} type="text" placeholder="Nachname..."/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">E-Mail</Label>
                                <Field name="email" className="form-control" component={customInputForm} type="email"
                                       placeholder="E-Mail..."/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="sex">Geschlecht</Label>
                                <Field id="sex" name='sex' component={customReactSelect} options={[
                                    SEX.MALE,
                                    SEX.FEMALE
                                ]}/>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={hideModal}>Abbrechen</Button>
                            <Button type="submit" className="primary">Benutzer speichern</Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    }
}

EditUserModal = connect((state, ownProps) => ({
    user: state.modal.getIn([ownProps.modalName, 'user']),
    userTableName: state.modal.getIn([ownProps.modalName, 'userTableName']),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
    updateUser: (user, userTableName) => (dispatch({
        type: UPDATE_USER,
        user: user,
        userTableName: userTableName
    }))
}))(EditUserModal);

export default EditUserModal;