import React from "react";
import {connect} from "react-redux";
import AdminHeader from "../../components/Headers/AdminHeader";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    FormFeedback,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {SEX as Sex} from "../../enums";
import {routeCreator} from "../../routesMap";
import {MEMBERS_LIST} from "../../types";
import {array, number, object, string} from 'yup';
import moment from 'moment';
import {CREATE_MEMBER, UPDATE_MEMBER} from "../../actions";
import {List} from "immutable";
import {customAsyncReactSelect, customInputForm, customInputRadio, customReactSelect} from "../../customComponents";

const isRequired = (businessKey, paymentTypes, field) => {
    const paymentType = paymentTypes.find(paymentType => paymentType.get('businessKey') === businessKey);
    if (paymentType) {
        return (paymentType.get('requiredFields') || List()).includes(field);
    }

    return true;
};

const MemberSchema = object().shape({
    paymentType: number().positive().required('Es muss eine Beitragsgruppe ausgewählt werden'),
    courses: array().when(['paymentType', 'paymentTypes'], {
        is: (paymentType, paymentTypes) => isRequired(paymentType, paymentTypes, 'courses'),
        then: array().required('Mindestens ein Kurs muss ausgewählt werden'),
        otherwise: array()
    }),
    lastname: string().required('Pflichtfeld'),
    firstname: string().required('Pflichtfeld'),
    birthdate: string().when(['paymentType', 'paymentTypes'], {
        is: (paymentType, paymentTypes) => isRequired(paymentType, paymentTypes, 'birthdate'),
        then: string().required('Pflichtfeld'),
        otherwise: string()
    }).test('isValidDate', '${value} ist kein gültiges Geburtsdatum', value => !value || moment(value, "DD.MM.YYYY").isValid()),
    street: string().required('Pflichtfeld'),
    zipCode: string().required('Pflichtfeld'),
    city: string().required('Pflichtfeld'),
    legalGuardian: string().nullable(),
    sex: object().when(['paymentType', 'paymentTypes'], {
      is: (paymentType, paymentTypes) => isRequired(paymentType, paymentTypes, 'sex'),
      then: object().required('Pflichtfeld'),
      otherwise: object()
    }),
    comment: string().nullable(),
    relatives: array()
});

class MemberEdit extends React.Component {

    constructor(props) {
        super(props);
    }

    checkPaymentTypeMulti(paymentTypeBusinessKey) {
        const {paymentTypes} = this.props;
        const filteredPaymentTypes = paymentTypes.filter((paymentType) => paymentType.get('businessKey') === paymentTypeBusinessKey),
            paymentType = filteredPaymentTypes ? filteredPaymentTypes.get(0) : null;

        console.log("paymentType", paymentTypeBusinessKey, paymentTypes, paymentType, paymentType && paymentType.get('multiple') === true);

        return paymentType && paymentType.get('multiple') === true;
    }

    checkMemberEdit(prop, valueConverter) {
        const {memberId, memberEdit} = this.props;

        return memberEdit && memberEdit.get('businessKey').toString() === memberId ?
            (valueConverter ? valueConverter(memberEdit.getIn(prop)) : (memberEdit.getIn(prop)) || '') : '';
    }

    convertStringToNumber(e, setFieldValue) {
        setFieldValue(e.target.name, parseInt(e.target.value, 10));
    }

    render() {
        const {paymentTypes, courses, gotoMembersList, createMember, memberId, activeSeason, memberEdit} = this.props;

        return <>
            <AdminHeader/>
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <h3 className="mb-0 float-left">Mitglied
                                    {memberEdit && memberEdit.get('businessKey').toString() === memberId ?
                                        ` ${memberEdit.get('firstname')} ${memberEdit.get('lastname')} bearbeiten` : ' erstellen'}</h3>
                            </CardHeader>
                            <Formik enableReinitialize
                                    initialValues={{
                                        paymentTypes: this.props.paymentTypes,
                                        paymentType: this.checkMemberEdit(['paymentType', 'businessKey']),
                                        courses: activeSeason ? this.checkMemberEdit(['courses', activeSeason.get('season').toString()], (courses) => courses ? courses.map((course) => ({
                                            label: course.get('name'),
                                            value: course.get('businessKey')
                                        })).toJS() : []) : [],
                                        lastname: this.checkMemberEdit(['lastname']),
                                        firstname: this.checkMemberEdit(['firstname']),
                                        birthdate: this.checkMemberEdit(['birthdate'], (birthdate) => moment(birthdate).format('DD.MM.YYYY')),
                                        street: this.checkMemberEdit(['address', 'street']),
                                        zipCode: this.checkMemberEdit(['address', 'zipCode']),
                                        city: this.checkMemberEdit(['address', 'city']),
                                        legalGuardian: this.checkMemberEdit(['legalGuardian']),
                                        sex: this.checkMemberEdit(['sex'], (sex) => Sex[sex]),
                                        comment: this.checkMemberEdit(['comment']),
                                        relatives: this.checkMemberEdit(['relatives'], (relatives) => relatives ? relatives.map((relative) => ({
                                            label: relative.get(1),
                                            value: relative.get(0)
                                        })).toJS() : [])
                                    }}
                                    validationSchema={MemberSchema}
                                    onSubmit={(values, {setSubmitting, resetForm}) => {
                                        createMember({
                                            paymentType: values.paymentType,
                                            courseIds: List(values.courses || []).map(course => course.value).toJS(),
                                            lastname: values.lastname,
                                            firstname: values.firstname,
                                            birthdate: values.birthdate ? moment(values.birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD') : null,
                                            address: {
                                                street: values.street,
                                                zipCode: values.zipCode,
                                                city: values.city
                                            },
                                            businessKey: memberId,
                                            legalGuardian: values.legalGuardian,
                                            sex: values.sex.value,
                                            comment: values.comment,
                                            relatives: values.relatives ? List(values.relatives).map(relative => [relative.value, relative.label]).toJS() : null
                                        }, memberId, setSubmitting, resetForm);
                                    }}>
                                {({isSubmitting, setFieldValue, errors, touched, values}) => (
                                    <Form>

                                        <CardBody>

                                            <Row>
                                                <Col md={12} lg={6}>
                                                    <FormGroup tag="fieldset">
                                                        <legend>
                                                            <h6 className="heading-small text-muted mb-4">
                                                                Beitragsgruppe
                                                            </h6>
                                                        </legend>
                                                        <div className="pl-lg-4">
                                                            {paymentTypes ? paymentTypes.filter((val, idx) => idx % 2 === 0)
                                                                .zip(paymentTypes.filter((val, idx) => idx % 2 !== 0)).map((val, idx) =>
                                                                    <Row key={idx}>
                                                                        <Col md={6}>
                                                                            <FormGroup check disabled>
                                                                                <Label className="form-control-label"
                                                                                       check>
                                                                                    <Field id={idx * 2}
                                                                                           name='paymentType'
                                                                                           value={val[0].get('businessKey')}
                                                                                           component={customInputRadio}
                                                                                           onChange={(e) => this.convertStringToNumber(e, setFieldValue)}/>
                                                                                    {' '}{val[0].get('name')}
                                                                                </Label>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <FormGroup check disabled>
                                                                                <Label className="form-control-label"
                                                                                       check>
                                                                                    <Field id={idx * 2 + 1}
                                                                                           name='paymentType'
                                                                                           component={customInputRadio}
                                                                                           onChange={(e) => this.convertStringToNumber(e, setFieldValue)}
                                                                                           value={val[1].get('businessKey')}/>
                                                                                    {' '}{val[1].get('name')}
                                                                                </Label>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                ) : ''
                                                            }
                                                            {errors['paymentType'] && touched['paymentType'] ? (
                                                                <FormFeedback
                                                                    className='d-block'>{errors['paymentType']}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={12} lg={6}>
                                                    <FormGroup tag='fieldset'>
                                                        <legend>
                                                            <h6 className="heading-small text-muted mb-4">
                                                                Kurse
                                                            </h6>
                                                        </legend>
                                                        <div className="pl-lg-4">
                                                            <Field name='courses'
                                                                   component={customReactSelect}
                                                                   options={courses ? courses.map(course => ({
                                                                       value: course.get('businessKey'),
                                                                       label: course.get('name')
                                                                   })) : []}
                                                                   closeMenuOnSelect={false}
                                                                   isMulti/>
                                                        </div>
                                                    </FormGroup>

                                                </Col>
                                            </Row>
                                            <hr className="my-4"/>
                                            <FormGroup tag="fieldset">
                                                <legend>
                                                    <h6 className="heading-small text-muted mb-4">
                                                        Mitgliedsdaten
                                                    </h6>
                                                </legend>
                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label className="form-control-label"
                                                                       for="lastname">Zuname</Label>
                                                                <Field id='lastname' placeholder="Zuname..." type="text"
                                                                       autoComplete="lastname"
                                                                       name="lastname" component={customInputForm}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label className="form-control-label"
                                                                       for="firstname">Vorname</Label>
                                                                <Field id='firstname' placeholder="Vorname..."
                                                                       type="text"
                                                                       autoComplete="firstname"
                                                                       name="firstname" component={customInputForm}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label className="form-control-label"
                                                                       for="birthdate">Geburtsdatum</Label>
                                                                <InputGroup className="mb-3">
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="ni ni-calendar-grid-58"/>
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Field id='birthdate' placeholder="Geburtsdatum..."
                                                                           type="text"
                                                                           autoComplete="birthdate"
                                                                           name="birthdate"
                                                                           component={customInputForm}/>
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label className="form-control-label"
                                                                       for="street">Straße</Label>
                                                                <InputGroup className="mb-3">
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="ni ni-square-pin"/>
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Field id='street' placeholder="Straße..."
                                                                           type="text"
                                                                           autoComplete="street"
                                                                           name="street" component={customInputForm}/>
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label className="form-control-label"
                                                                       for="zipCode">PLZ</Label>
                                                                <Field id='zipCode' placeholder="PLZ..." type="text"
                                                                       autoComplete="zipCode"
                                                                       name="zipCode" component={customInputForm}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label className="form-control-label"
                                                                       for="city">Ort</Label>
                                                                <InputGroup className="mb-3">
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="ni ni-building"/>
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Field id='city' placeholder="Ort..." type="text"
                                                                           autoComplete="city"
                                                                           name="city" component={customInputForm}/>
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className='justify-content-center'>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label className="form-control-label"
                                                                       for="legalGuardian">Bei
                                                                    Minderjährigen:
                                                                    Name des
                                                                    Erziehungsberichtigten</Label>
                                                                <InputGroup className="mb-3">
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="ni ni-single-02"/>
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Field id='legalGuardian'
                                                                           placeholder="Erziehungsberechtigter..."
                                                                           type="text"
                                                                           autoComplete="legalGuardian"
                                                                           name="legalGuardian"
                                                                           component={customInputForm}/>
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label className="form-control-label"
                                                                       for="sex">Geschlecht</Label>
                                                                <Field id='sex' name="sex" component={customReactSelect}
                                                                       options={[
                                                                           Sex.MALE,
                                                                           Sex.FEMALE
                                                                       ]}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        {this.checkPaymentTypeMulti(values.paymentType) &&
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label className="form-control-label"
                                                                       for="comment">Familienangehörige</Label>
                                                                <Field id='relatives' name='relatives'
                                                                       isMulti
                                                                       component={customAsyncReactSelect}/>
                                                            </FormGroup>
                                                        </Col>
                                                        }
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label className="form-control-label" for="comment">Weitere
                                                                    Notizen</Label>
                                                                <InputGroup className="mb-3">
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                            <i className="ni ni-align-left-2"/>
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Field id='comment' placeholder="Notizen..."
                                                                           type="textarea"
                                                                           autoComplete="comment"
                                                                           name="comment" component={customInputForm}
                                                                           rows={5}/>
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </FormGroup>

                                        </CardBody>
                                        <CardFooter>
                                            <Button color='primary' type='submit' disabled={isSubmitting}>Mitglied
                                                {memberId ? ' speichern' : ' hinzufügen'}</Button>
                                            <Button color='secondary' type='button' disabled={isSubmitting}
                                                    onClick={() => gotoMembersList()}>Zurück zur
                                                Mitgliederlist (Daten
                                                gehen verloren)</Button>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>;
    }
}

export default connect(state => ({
    paymentTypes: state.sua.get('paymentTypes'),
    courses: state.sua.get('courses'),
    memberId: state.location.payload.id,
    memberEdit: state.sua.get('memberEdit'),
    activeSeason: state.sua.get('activeSeason')
}), dispatch => ({
    gotoMembersList: () => dispatch(routeCreator(MEMBERS_LIST)),
    createMember: (values, id, setSubmitting, resetForm) => dispatch({
        type: !!id ? UPDATE_MEMBER : CREATE_MEMBER,
        member: values,
        businessKey: id,
        setSubmitting: setSubmitting,
        resetForm: resetForm
    })
}))(MemberEdit);