const CTX = "/",
    FULL_URL = "http://localhost:3000/sua-ui",
    ONLY_ACTIVE_FILTER = 'ACTIVE',
    STATE = "state",
    SEASON = 'season',
    PAYMENT_TYPE = 'paymentType',
    ASCENDING = 'ASC',
    DESCENDING = 'DESC',
    APPLICATION_JSON = 'application/json',
    TEXT_PLAIN = 'text/plain';

export {CTX, FULL_URL, ONLY_ACTIVE_FILTER, STATE, SEASON, PAYMENT_TYPE, ASCENDING, DESCENDING, APPLICATION_JSON, TEXT_PLAIN};