import {EDIT_USER_SEX_CHANGED, UPDATE_USER} from "../../actions";
import {Map} from "immutable";
import Api from "../../api/Api";

const initialState = Map({
        sex: {}
    }),
    actionMap = {
        [EDIT_USER_SEX_CHANGED]: editUserSexChanged
    };

function editUserSexChanged(state, action) {
    return state.set("sex", action.value);
}

function editCourseModalReducer(state = initialState, action) {
    if (actionMap[action.type] && typeof actionMap[action.type] === "function") {
        return actionMap[action.type](state, action);
    }

    return state;
}

export default editCourseModalReducer;