import React from "react";
import {connect} from "react-redux";
import AdminHeader from "../components/Headers/AdminHeader";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import ServerSideTable from "../components/ServerSideTable";
import {List, Map} from "immutable";
import * as CurrencyFormat from 'react-currency-format';
import AddPaymentTypeModal from "./AddPaymentTypeModal";
import {showModalCreator} from "../reducers/modalReducer";
import DeletePaymentTypeModal from "./DeletePaymentTypeModal";

class PaymentTypes extends React.Component {

    constructor(props) {
        super(props);

        this.addPaymentTypeModal = 'AddPaymentTypeModal';
        this.deletePaymentTypeModal = 'DeletePaymentTypeModal';
        this.tableName = 'PaymentTypesTable';
    }

    render() {
        const {paymentTypes, showAddPaymentTypeModal, showDeletePaymentTypeModal} = this.props;

        return (
            <>
                <AdminHeader/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0 float-left">Beitragsgrupppen</h3>
                                </CardHeader>

                                <CardBody>
                                    <ServerSideTable columns={List([
                                        Map([
                                            ['key', 'name'],
                                            ['display', 'Name']
                                        ]),
                                        Map([
                                            ['key', 'amount'],
                                            ['display', 'Preis'],
                                            ['valueConverter', (val) => <CurrencyFormat value={val ? val : 0} displayType={'text'}
                                                                                        thousandSeparator={true} prefix={'€'}/>]
                                        ]),
                                        Map([
                                            ['key', 'notes'],
                                            ['display', 'Notizen']
                                        ]),
                                        Map([
                                            ['key', ''],
                                            ['display', ''],
                                            ['sortable', false],
                                            ['valueConverter', (val, fullData) => <UncontrolledDropdown>
                                                <DropdownToggle
                                                    className="btn-icon-only text-light"
                                                    href="#"
                                                    role="button"
                                                    size="sm"
                                                    color=""
                                                    onClick={e => e.preventDefault()}>
                                                    <i className="fas fa-ellipsis-v"/>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                    <DropdownItem href="#"
                                                                  onClick={() => showAddPaymentTypeModal(this.addPaymentTypeModal, fullData)}>
                                                        <i className="fas fa-pencil-alt"></i>
                                                        Editieren
                                                    </DropdownItem>
                                                    <DropdownItem href="#"
                                                                  onClick={() => showDeletePaymentTypeModal(this.deletePaymentTypeModal, fullData)}>
                                                        <i className="far fa-trash-alt"></i>
                                                        Löschen
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>]
                                        ])])} values={paymentTypes} isLocal={true} tableName={this.tableName}
                                                     textSearchProps={List(['name'])}></ServerSideTable>
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col md={2}>
                                            <Button type='button' color='primary'
                                                    onClick={() => showAddPaymentTypeModal(this.addPaymentTypeModal)}>
                                                Neue Beitragsgruppe
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                    <AddPaymentTypeModal modalName={this.addPaymentTypeModal}/>
                    <DeletePaymentTypeModal modalName={this.deletePaymentTypeModal}/>
                </Container>
            </>);
    }
}

export default connect(state => ({
        paymentTypes: state.sua.get('paymentTypes')
    }),
    dispatch => ({
        showAddPaymentTypeModal: (modalName, paymentType) => dispatch(showModalCreator(modalName, {paymentTypeEdit: paymentType})),
        showDeletePaymentTypeModal: (modalName, paymentType) => dispatch(showModalCreator(modalName, {paymentTypeDelete: paymentType})),
    })
)(PaymentTypes);