import {fromJS, List, Map} from 'immutable';
import {ASCENDING, DESCENDING} from "../constants";
import Api from "../api/Api";

const state = Map(),
    fetchDataThunk = (tableName) => {
        return (dispatch, getState) => {
            // table is the reducer's name
            const _state = getState().table.get(tableName),
                api = _state.get('api'),
                sort = _state.get('columns').get(_state.get('sort')).get('key'),
                sortOrder = _state.get('sortOrder'),
                page = _state.get('page'),
                pageSize = _state.get('pageSize');
            let search = _state.get('searchText');
            let params = _state.get('params');

            params = params ? params.reduce((reduction, val, key) => reduction + `&${key}=${val}`, "") : "";
            search = search ? `&search=${search}` : '';

            Api.fetchTableData(api, sort, sortOrder, page, pageSize, search, params, tableName).then((responseJson) => {
                dispatch({
                    type: FETCH_DATA_SUCCESSFUL,
                    data: responseJson.data,
                    totalCount: responseJson.totalCount,
                    filteredCount: responseJson.filteredCount,
                    tableName: tableName
                })
            }).catch((error) => {
                dispatch({type: FETCH_DATA_ERROR, error: error, tableName: tableName});
            });
        };
    },
    FETCH_DATA_SUCCESSFUL = 'FETCH_DATA_SUCCESSFUL',
    INIT_NEW_TABLE = 'INIT_NEW_TABLE',
    FETCH_DATA_ERROR = 'FETCH_DATA_ERROR',
    CLEAR_ERRORS = 'CLEAR_ERRORS',
    CHANGE_PAGE = "CHANGE_PAGE",
    CHANGE_SORT = "CHANGE_SORT",
    ADD_PARAM = "ADD_PARAM",
    REMOVE_PARAM = "REMOVE_PARAM",
    CHANGE_SEARCH = "CHANGE_SEARCH",
    ADD_FILTER = "ADD_FILTER",
    REMOVE_FILTER = "REMOVE_FILTER",
    initialState = Map(),
    _initialState = Map({
        page: 1,
        pageSize: 50,
        data: List(),
        api: "/data",
        sort: 0,
        sortOrder: ASCENDING,
        filters: Map(),
        params: Map()
    }),
    actionMap = {
        [INIT_NEW_TABLE]: initNewTable,
        [FETCH_DATA_SUCCESSFUL]: newDataReceived,
        [FETCH_DATA_ERROR]: fetchDataFailed,
        [CLEAR_ERRORS]: clearErrors,
        [CHANGE_PAGE]: changePage,
        [CHANGE_SORT]: changeSort,
        [ADD_PARAM]: addGetParam,
        [REMOVE_PARAM]: removeGetParam,
        [CHANGE_SEARCH]: changeSearch,
        [ADD_FILTER]: addFilter,
        [REMOVE_FILTER]: removeFilter
    },
    clearErrorsCreator = (tableName) => ({type: CLEAR_ERRORS, tableName: tableName}),
    changePageCreator = (tableName, next) => ({type: CHANGE_PAGE, tableName: tableName, next: next}),
    initNewTableCreator = (tableName, initialState) => ({
        type: INIT_NEW_TABLE,
        tableName: tableName,
        initialState: initialState
    }),
    changeSortCreator = (tableName, sort) => ({type: CHANGE_SORT, tableName: tableName, sort: sort}),
    addGetParamCreator = (tableName, key, val) => ({type: ADD_PARAM, tableName: tableName, key: key, val: val}),
    removeGetParamCreator = (tableName, key) => ({type: REMOVE_PARAM, tableName: tableName, key: key}),
    searchCreator = (tableName, search) => ({type: CHANGE_SEARCH, tableName: tableName, searchText: search}),
    clearSearchCreator = (tableName) => ({type: CHANGE_SEARCH, tableName: tableName}),
    addFilterCreator = (tableName, filterKey, filterFunction) => ({
        type: ADD_FILTER,
        tableName: tableName,
        filterKey: filterKey,
        filterFunction: filterFunction
    }),
    removeFilterCreator = (tableName, filterKey) => ({type: REMOVE_FILTER, tableName: tableName, filterKey: filterKey});

function applyFilters(state, tableName) {
    const data = state.getIn([tableName, 'fullData']),
        textSearchProps = state.getIn([tableName, 'textSearchProps']),
        search = state.getIn([tableName, 'searchText']) || "",
        filters = state.getIn([tableName, 'filters']);

    return data.filter(el =>
        textSearchProps.reduce((reduction, searchProp) => reduction ||
            ((el.get(searchProp) && el.get(searchProp).toLowerCase().indexOf(search.toLowerCase()) !== -1) &&
                (filters.reduce((_reduction, filterFunction) => _reduction && filterFunction(el), true))), false));
}

function doFilterAndSetInState(state, tableName) {
    const filteredData = applyFilters(state, tableName),
        pageSize = state.getIn([tableName, 'pageSize']);

    return state.setIn([tableName, 'filteredData'], filteredData).setIn([tableName, 'filteredCount'], filteredData.size)
        .setIn([tableName, 'data'], filteredData.take(pageSize));
}

function sort(state, tableName) {
    const sortOrder = state.getIn([tableName, 'sortOrder']),
        columns = state.getIn([tableName, 'columns']),
        sort = state.getIn([tableName, 'sort']);

    return state.getIn([tableName, 'filteredData']).sortBy(entry => entry.get(columns.get(sort).get('key')), comparator(sortOrder));
}

function addFilter(state, action) {
    state = state.setIn([action.tableName, 'filters', action.filterKey], action.filterFunction);
    return doFilterAndSetInState(state, action.tableName);
}

function removeFilter(state, action) {
    state = state.deleteIn([action.tableName, 'filters', action.filterKey]);
    return doFilterAndSetInState(state, action.tableName);
}

function changeSearch(state, action) {
    if (state.getIn([action.tableName, 'isLocal']) === true) {
        state = state.setIn([action.tableName, 'searchText'], action.searchText).setIn([action.tableName, 'page'], 1);
        return doFilterAndSetInState(state, action.tableName);
    }

    return state.setIn([action.tableName, 'searchText'], action.searchText);
}

function addGetParam(state, action) {
    return state.updateIn([action.tableName, 'params'], params => params ? params.set(action.key, action.val) : Map({[action.key]: action.val}));
}

function removeGetParam(state, action) {
    return state.updateIn([action.tableName, 'params'], params => params ? params.delete(action.key) : Map());
}

function changeSort(state, action) {
    let sortOrder = state.getIn([action.tableName, 'sortOrder']);
    if (action.sort === state.getIn([action.tableName, 'sort'])) {
        sortOrder = sortOrder === ASCENDING ? DESCENDING : ASCENDING;
    }
    if (state.getIn([action.tableName, 'isLocal']) === true) {
        const sorted = sort(state, action.tableName),
            pageSize = state.getIn([action.tableName, 'pageSize']);

        return state.setIn([action.tableName, 'sortOrder'], sortOrder).setIn([action.tableName, 'sort'], action.sort)
            .setIn([action.tableName, 'filteredData'], sorted).setIn([action.tableName, 'data'], sorted.take(pageSize))
    } else {
        return state.setIn([action.tableName, 'sortOrder'], sortOrder).setIn([action.tableName, 'sort'], action.sort);
    }
}

function changePage(state, action) {
    if (state.getIn([action.tableName, 'isLocal']) === true) {
        const pageSize = state.getIn([action.tableName, 'pageSize']),
            data = state.getIn([action.tableName, 'filteredData']);
        return state.setIn([action.tableName, 'page'], action.next)
            .setIn([action.tableName, 'data'], data.skip((action.next - 1) * pageSize).take(pageSize));
    } else {
        return state.setIn([action.tableName, 'page'], action.next);
    }
}

function clearErrors(state, action) {
    return state.deleteIn([action.tableName, 'error']);
}

function fetchDataFailed(state, action) {
    return state.setIn([action.tableName, 'error'], 'Fehler beim Laden der Daten. Bitte überprüfen Sie Ihre Internetverbindung.');
}

function newDataReceived(state, action) {
    return state.setIn([action.tableName, "data"], fromJS(action.data)).setIn([action.tableName, 'totalCount'], action.totalCount)
        .setIn([action.tableName, 'filteredCount'], action.filteredCount);
}

const comparator = (direction) => (a, b) => {
    if (direction === ASCENDING) {
        if (typeof a === "string") {
            return a.localeCompare(b);
        }

        return a === b ? 0 : (a < b ? -1 : 1);
    } else if (direction === DESCENDING) {
        if (typeof a === "string") {
            return b.localeCompare(a);
        }

        return a === b ? 0 : (a < b ? 1 : -1);
    }
};

function initNewTable(state, action) {
    console.log(state, action);
    if (!state.has(action.tableName) || !action.initialState.disableReinit) {
        if (action.initialState.isLocal === true) {
            action.initialState.filteredData = action.initialState.fullData = action.initialState.data.sortBy(entry =>
                entry.get(action.initialState.columns.get(action.initialState.sort).get('key')), comparator(action.initialState.sortOrder));
            action.initialState.data = action.initialState.fullData.take(action.initialState.pageSize);
            action.initialState.totalCount = action.initialState.fullData.size;
            action.initialState.filteredCount = action.initialState.filteredData.size;
        }

        return state.set(action.tableName, _initialState.mergeDeep(action.initialState));
    }

    return state;
}

function tableReducer(state = initialState, action) {
    if (actionMap[action.type] && typeof actionMap[action.type] === "function") {
        return actionMap[action.type](state, action);
    }

    return state;
}

export
{
    // thunks
    fetchDataThunk,
    // action creators
    clearErrorsCreator,
    changePageCreator,
    initNewTableCreator,
    changeSortCreator,
    addGetParamCreator,
    removeGetParamCreator,
    searchCreator,
    clearSearchCreator,
    addFilterCreator,
    removeFilterCreator
};

export default tableReducer;