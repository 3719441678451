/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// reactstrap components
import {Card, CardHeader, Col, Container, Row, Table} from "reactstrap";
// core components
import {chartOptions, parseOptions} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import {connect} from 'react-redux';
import {fetchDashboardDataCreator} from "../../api/apiCreators";
import CourseInfoModal from "../course/CourseInfoModal";
import DashboardUnassignedMembersModal from "./DashboardUnassignedMembersModal";
import {showModalCreator} from "../../reducers/modalReducer";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeNav: 1,
            chartExample1Data: "data1"
        };
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }

        this.showDashboardUnassignedMembersModalName = 'showDashboardUnassignedMembersModalName';
        this.unassignedMembersTableName = 'unassignedMembersTableName';
    }

    toggleNavs = (e, index) => {
        e.preventDefault();
        this.setState({
            activeNav: index,
            chartExample1Data:
                this.state.chartExample1Data === "data1" ? "data2" : "data1"
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevSeason = prevProps.activeSeason && prevProps.activeSeason.get('season') ? prevProps.activeSeason.get('season') : null;
        const newSeason = this.props.activeSeason && this.props.activeSeason.get('season') ? this.props.activeSeason.get('season') : null;

        if (prevSeason !== newSeason) {
            this.props.fetchDashboardData(newSeason);
        }
    }

    render() {
        const {dashboardData, showDashboardUnassignedMembersModal, activeSeason} = this.props;
        const fitnessHealth = dashboardData ? dashboardData.get('fitnessHealth') : null;
        const competition = dashboardData ? dashboardData.get('competition') : null;

        const getStatistics = (_props) => {
            const {fp, cp} = _props;

            let sum = 0;
            if (competition && cp) {
                sum += Array.isArray(cp) ? cp.reduce((acc, _prop) => acc + competition.get(_prop), 0) : competition.get(cp);
            }
            if (fitnessHealth && fp) {
                sum += Array.isArray(fp) ? fp.reduce((acc, _prop) => acc + fitnessHealth.get(_prop), 0) : fitnessHealth.get(fp);
            }

            return sum;
        };

        return (
            <>
                <Header/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/*<Row>
                        <Col className="mb-5 mb-xl-0" xl="8">
                            <Card className="bg-gradient-default shadow">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-light ls-1 mb-1">
                                                Overview
                                            </h6>
                                            <h2 className="text-white mb-0">Sales value</h2>
                                        </div>
                                        <div className="col">
                                            <Nav className="justify-content-end" pills>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames("py-2 px-3", {
                                                            active: this.state.activeNav === 1
                                                        })}
                                                        href="#pablo"
                                                        onClick={e => this.toggleNavs(e, 1)}
                                                    >
                                                        <span className="d-none d-md-block">Month</span>
                                                        <span className="d-md-none">M</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames("py-2 px-3", {
                                                            active: this.state.activeNav === 2
                                                        })}
                                                        data-toggle="tab"
                                                        href="#pablo"
                                                        onClick={e => this.toggleNavs(e, 2)}
                                                    >
                                                        <span className="d-none d-md-block">Week</span>
                                                        <span className="d-md-none">W</span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>*/}
                    {/* Chart */}
                    {/*<div className="chart">
                                        <Line
                                            data={chartExample1[this.state.chartExample1Data]}
                                            options={chartExample1.options}
                                            getDatasetAtEvent={e => console.log(e)}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card className="shadow">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-muted ls-1 mb-1">
                                                Performance
                                            </h6>
                                            <h2 className="mb-0">Total orders</h2>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>*/}
                    {/* Chart */}
                    {/*<div className="chart">
                                        <Bar
                                            data={chartExample2.data}
                                            options={chartExample2.options}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>*/}
                    <Row className="mt-5">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Statistiken</h3>
                                        </div>
                                        {/*<div className="col text-right">
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                onClick={e => e.preventDefault()}
                                                size="sm"
                                            >
                                                See all
                                            </Button>
                                        </div>*/}
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col" rowSpan="2">Mitgieder</th>
                                        <th scope="col" colSpan="2">Wettkampf im Fachverband</th>
                                        <th scope="col" colSpan="2">Fitness/Gesundheit</th>
                                        <th scope="col" rowSpan="2">Gesamt</th>
                                    </tr>
                                    <tr>
                                        <th scope="col">weiblich</th>
                                        <th scope="col">männlich</th>
                                        <th scope="col">weiblich</th>
                                        <th scope="col">männlich</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row">bis 13 Jahre</th>
                                        <td>{getStatistics({cp: 'female13'})}</td>
                                        <td>{getStatistics({cp: 'male13'})}</td>
                                        <td>{getStatistics({fp: 'female13'})}</td>
                                        <td>{getStatistics({fp: 'male13'})}</td>
                                        <td>{getStatistics({cp: 'female13', fp: 'female13'}) + getStatistics({
                                            cp: 'male13',
                                            fp: 'male13'
                                        })}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">14 - 18 Jahre</th>
                                        <td>{getStatistics({cp: 'female1418'})}</td>
                                        <td>{getStatistics({cp: 'male1418'})}</td>
                                        <td>{getStatistics({fp: 'female1418'})}</td>
                                        <td>{getStatistics({fp: 'male1418'})}</td>
                                        <td>{getStatistics({cp: 'female1418', fp: 'female1418'}) + getStatistics({
                                            cp: 'male1418',
                                            fp: 'male1418'
                                        })}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Erwachsene</th>
                                        <td>{getStatistics({cp: 'femaleGrownUps'})}</td>
                                        <td>{getStatistics({cp: 'maleGrownUps'})}</td>
                                        <td>{getStatistics({fp: 'femaleGrownUps'})}</td>
                                        <td>{getStatistics({fp: 'maleGrownUps'})}</td>
                                        <td>{getStatistics({
                                            cp: 'femaleGrownUps',
                                            fp: 'femaleGrownUps'
                                        }) + getStatistics({
                                            cp: 'maleGrownUps',
                                            fp: 'maleGrownUps'
                                        })}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Senioren (65+)</th>
                                        <td>{getStatistics({cp: 'femaleSeniors'})}</td>
                                        <td>{getStatistics({cp: 'maleSeniors'})}</td>
                                        <td>{getStatistics({fp: 'femaleSeniors'})}</td>
                                        <td>{getStatistics({fp: 'maleSeniors'})}</td>
                                        <td>{getStatistics({cp: 'femaleSeniors', fp: 'femaleSeniors'}) + getStatistics({
                                            cp: 'maleSeniors',
                                            fp: 'maleSeniors'
                                        })}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Unterstützende</th>
                                        <td>{getStatistics({cp: 'femaleSupporting'})}</td>
                                        <td>{getStatistics({cp: 'maleSupporting'})}</td>
                                        <td>{getStatistics({fp: 'femaleSupporting'})}</td>
                                        <td>{getStatistics({fp: 'maleSupporting'})}</td>
                                        <td>{getStatistics({
                                            cp: 'femaleSupporting',
                                            fp: 'femaleSupporting'
                                        }) + getStatistics({
                                            cp: 'maleSupporting',
                                            fp: 'maleSupporting'
                                        })}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Gesamt</th>
                                        <td>{getStatistics({cp: ['female13', 'female1418', 'femaleGrownUps', 'femaleSeniors', 'femaleSupporting']})}</td>
                                        <td>{getStatistics({cp: ['male13', 'male1418', 'maleGrownUps', 'maleSeniors', 'maleSupporting']})}</td>
                                        <td>{getStatistics({fp: ['female13', 'female1418', 'femaleGrownUps', 'femaleSeniors', 'femaleSupporting']})}</td>
                                        <td>{getStatistics({fp: ['male13', 'male1418', 'maleGrownUps', 'maleSeniors', 'maleSupporting']})}</td>
                                        <td>{getStatistics({
                                            cp: ['female13', 'female1418', 'femaleGrownUps', 'femaleSeniors', 'femaleSupporting', 'male13', 'male1418', 'maleGrownUps', 'maleSeniors', 'maleSupporting'],
                                            fp: ['female13', 'female1418', 'femaleGrownUps', 'femaleSeniors', 'femaleSupporting', 'male13', 'male1418', 'maleGrownUps', 'maleSeniors', 'maleSupporting']
                                        })}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                                <div className='mt-5 col-12'>
                                    <a href='javascript:void(0)'
                                       onClick={() => showDashboardUnassignedMembersModal(this.showDashboardUnassignedMembersModalName, dashboardData.get('activeMembersNotIncluded'))}>Nicht
                                        zugeordnete Mitglieder, aber für die
                                        Saison {activeSeason ? activeSeason.get('season') - 1 : ''}/{activeSeason ? activeSeason.get('season') : ''} aktiv
                                        gesetzt.</a>
                                </div>
                                <DashboardUnassignedMembersModal
                                    modalName={this.showDashboardUnassignedMembersModalName}
                                    tableName={this.unassignedMembersTableName}/>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default connect(state => ({
    activeSeason: state.sua.get('activeSeason'),
    dashboardData: state.sua.get('dashboardData')
}), dispatch => ({
    fetchDashboardData: (season) => dispatch(fetchDashboardDataCreator(season)),
    showDashboardUnassignedMembersModal: (modalName, unassignedMembers) => dispatch(showModalCreator(modalName, {unassignedMembers: unassignedMembers}))
}))(Dashboard);
