import React from "react";
import {connect} from "react-redux";
import {
    Button,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";
import {SEND_CODE} from "../../actions";
import {customInputForm} from "../../customComponents";

class SendCodeModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {user, sendCode, isOpen, backdrop, hideModal} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Senden des Aktivierungscodes</ModalHeader>
            <Formik enableReinitialize initialValues={{
                email: user ? user.get('email') : null,
            }} onSubmit={(values, actions) => {
                sendCode(user, values.email);
            }}>
                {props => (
                    <Form id="send-code-form" onSubmit={props.handleSubmit} role="form">
                        <ModalBody>
                            Bitte geben Sie hier die E-Mail Adresse des
                            Benutzers <strong>{user.get('firstname')} {user.get('lastname')}</strong> ein und bestätigen Sie mit
                            "Senden".
                            <FormGroup>
                                <Label>E-Mail</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-envelope"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>

                                    <Field name="email" type="email" component={customInputForm} placeholder="E-Mail..."/>
                                </InputGroup>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={hideModal}>Abbrechen</Button>
                            <Button type="submit" className="primary">Senden</Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    }
}

SendCodeModal = connect((state, ownProps) => ({
    user: state.modal.getIn([ownProps.modalName, 'user']),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
    sendCode: (user, email) => (dispatch({
        type: SEND_CODE,
        modalName: ownProps.modalName,
        email: email,
        user: user
    }))
}))(SendCodeModal);

export default SendCodeModal;