/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {Card, CardBody, CardHeader, Col} from "reactstrap";
import {connect} from "react-redux";
import {showLogin} from "../../actionCreators/generalActionCreators";

class RegisterSuccess extends React.Component {

    render() {
        const {userToActivate, goToLogin} = this.props;

        return userToActivate ? (
            <>
                <Col lg="6" md="8">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div className="text-center text-muted">
                                <h1>Registrierung erfolgreich!</h1>
                            </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <p>
                                {userToActivate.get('sex') === "MALE" ? "Lieber" : userToActivate.get('sex') === "FEMALE" ? "Liebe" : "Liebe/r"}
                                {userToActivate.get('firstname')}
                            </p>
                            <p>
                                Du hast dich erfolgreich für die Mitglieder-Datenbank der SPORTUNION Böheimkirchen als
                                {userToActivate.get('sex') === "MALE" ? " Übungsleiter" : userToActivate.get('sex') === "FEMALE" ? " Übungsleiterin" :
                                    " Übungsleiter/in"} registriert. Du kannst jetzt
                                unter deinem Benutzernamen und dem selbst gewählten
                                Passwort die Daten deiner Gruppe(n) einsehen.
                                <span> <a href='javascript:void(0)' onClick={() => goToLogin()}>Hier geht's zum Login.</a></span>
                            </p>
                            <p>
                                Mit lieben Grüßen
                            </p>
                            <p>
                                SPORTUNION Böheimkirchen
                            </p>
                        </CardBody>
                    </Card>
                </Col>
            </>
        ) : '';
    }
}

export default connect((state) => ({
    userToActivate: state.sua.get('userToActivate')
}), (dispatch) => ({
    goToLogin: () => dispatch(showLogin())
}))(RegisterSuccess);
