const ADD_PAYMENT_TYPE_MODAL = "add-payment-type-modal",
    DELETE_PAYMENT_TYPE_MODAL = "delete-payment-type-modal",
    ADD_PAYMENT_MODAL = "add-payment-modal",
    ADD_COURSE_MODAL = "add-course-modal",
    DELETE_COURSE_MODAL = "delete-course-modal",
    DELETE_USER_MODAL = "delete-user-modal",
    EDIT_USER_MODAL = "edit-user-modal",
    NEW_COACH_INFO_MODAL = "new-coach-info-modal",
    SEND_CODE_MODAL = "send-code-modal",
    COURSE_INFO_MODAL = "course-info",
    MEMBER_EXISTS_MODAL = "member-exists",
    FAMILY_MEMBERS_UPDATED_MODAL = "family-members-updated";

export {
    ADD_PAYMENT_TYPE_MODAL,
    DELETE_PAYMENT_TYPE_MODAL,
    ADD_PAYMENT_MODAL,
    ADD_COURSE_MODAL,
    DELETE_COURSE_MODAL,
    DELETE_USER_MODAL,
    NEW_COACH_INFO_MODAL,
    SEND_CODE_MODAL,
    COURSE_INFO_MODAL,
    EDIT_USER_MODAL,
    MEMBER_EXISTS_MODAL,
    FAMILY_MEMBERS_UPDATED_MODAL
};