import {Map} from "immutable";

const initialState = Map({
        isLoading: false
    }),
    types = {
        loadingTypes: [
            "CREATE_", "UPDATE_", "SEND_CODE"
        ],
        finishTypes: [
            "CREATED_", "UPDATED", "SUCCESS", "FAILED"
        ],
        excludes: [
            "@@redux-form/UPDATE_SYNC_ERRORS"
        ]
    },
    includes = (actionType, typeArray) => (typeArray.some((el) => actionType.includes(el)));

function loadingReducer(state = initialState, action) {
    if (types.excludes.includes(action.type)) {
        return state;
    }

    if (includes(action.type, types.finishTypes)) {
        return state.set("isLoading", false);
    } else if (includes(action.type, types.loadingTypes)) {
        return state.set("isLoading", true);
    }

    return state;
}

export default loadingReducer;