import {ALL_MEMBERS, MEMBERS_OF_CURRENT_SEASON} from "../types";
import {Map, List} from "immutable";

class Util {

    static get filterActions() {
        return {
            [ALL_MEMBERS]: this.filterAllMembers,
            [MEMBERS_OF_CURRENT_SEASON]: this.filterMembersOfCurrentSeason
        };
    }

    static arrayToObject(array, keyField) {
        return array.reduce((obj, item) => {
            obj[item[keyField]] = item;
            return obj;
        }, {});
    }

    static getName(member) {
        if (!member) {
            return "";
        }

        return member.firstname ? ((member.firstname + " ") + (member.lastname ? member.lastname : "")) : (member.lastname ? member.lastname : "")
    }

    static updateTableData(table, newData) {
        table.clear();
        table.rows.add(newData ? newData : []);
        table.draw();
    }

    static filterMembers(members, filterAction, seasonInstance) {
        return this.filterActions[filterAction](members, seasonInstance);
    }

    static filterAllMembers(members) {
        if (Map.isMap(members)) {
            return members.toList();
        }

        return members;
    }

    static filterMembersOfCurrentSeason(members, seasonInstance) {
        const season = seasonInstance ? seasonInstance.get("season") : null;

        if (season) {
            if (List.isList(members)) {
                return members.filter(member => member.get("activeSeasons").includes(season));
            } else if (Map.isMap(members)) {
                return members.toList().filter(member => (member.get("activeSeasons") && member.get("activeSeasons").includes(season)));
            }
        }
    }

    static seasonString(season) {
        if (typeof season === "string") {
            return (parseInt(season, 10) - 1) + "/" + season;
        } else if (typeof season === "number") {
            return (season - 1) + "/" + season;
        } else if (typeof season === "object") {
            return (season.get("season") - 1) + "/" + season.get("season");
        }
    }
}

export default Util;