import Api from "../api/Api";
import {call, put} from "redux-saga/es/effects";
import {CTX} from "../constants";
import {
    DISABLED_USER,
    ENABLED_USER,
    HIDE_MODAL,
    LIST_USERS_COMPLETE,
    SENT_CODE_SUCCESS,
    UPDATE_USER_FAILED,
    UPDATE_USER_SUCCESS,
    USER_DELETED,
    LOGIN_SUCCESS, CREATED_COURSE_SUCCESS, USER_BY_ACTIVATION_CODE, USER_BY_ACTIVATION_CODE_SUCCESS
} from "../actions";
import {DELETE_USER_MODAL, EDIT_USER_MODAL, SEND_CODE_MODAL} from "../modals";
import {error, success} from "react-notification-system-redux";
import {DASHBOARD, REGISTER_SUCCESS} from "../types";
import {hideModalCreator} from "../reducers/modalReducer";
import {redirect} from "redux-first-router";
import {fetchCoursesForCoachSuccessCreator} from "../api/apiCreators";

export function* usersUpdate(action) {
    try {
        const newUsername = yield call(Api.updateUser, action.user);
        action.user.username = newUsername;
        const users = yield call(Api.fetchUsers);
        yield put({type: UPDATE_USER_SUCCESS, users: users});
        yield put(success({
            title: "Editieren des Benutzers erfolgreich",
            message: "Das Editieren des Benutzers " + action.user.firstname + " " + action.user.lastname + " war erfolgreich!"
        }));
    } catch (updateUserError) {
        yield put(error({
            title: "Editieren des Benutzers fehlgeschlagen",
            message: "Das Editieren des Benutzers ist leider fehlgeschlagen. Bitte versuchen Sie es später erneut!"
        }));
        yield put({type: UPDATE_USER_FAILED, user: action.user});
    }
    yield put(hideModalCreator(EDIT_USER_MODAL));
}

export function* login(action) {
    try {
        const loginResponse = yield call(Api.login, action.username, action.password);
        yield put({type: LOGIN_SUCCESS, loginData: loginResponse});
        yield put({type: DASHBOARD});
    } catch (loginError) {
        yield put(error({
            title: "Anmeldung fehlgeschlagen",
            message: "Benutzername und/oder Passwort falsch"
        }));
        action.setSubmitting(false);
    }
}

export function* usersList(action) {
    const users = yield call(Api.fetchUsers);
    console.log("in generator function", users);
    yield put({type: LIST_USERS_COMPLETE, users: users});
}

export function* loginInit(action) {
}

export function* usersDelete(action) {
    const {user} = action;

    yield call(Api.deleteUser, user.get('businessKey'));

    const users = yield call(Api.fetchUsers);
    yield put({type: UPDATE_USER_SUCCESS, users: users});

    yield put(success({
        title: 'Benutzer erfolgreich gelöscht',
        message: `Der Benutzer ${user.get('firstname')} ${user.get('lastname')} wurde erfolgreich gelöscht!`
    }));

    yield put(hideModalCreator(action.modalName));
}

export function* usersDisable(action) {
    yield call(Api.disableUser, action.userId);
    yield put({type: DISABLED_USER, userId: action.userId});
}

export function* usersEnable(action) {
    yield call(Api.enableUser, action.userId);
    yield put({type: ENABLED_USER, userId: action.userId});
}

export function* usersSendCode(action) {
    const {user, email, modalName} = action;

    yield call(Api.sendCode, user.get('firstname'), `${window.location.origin}/register/${user.get('activationCode')}`, email, user.get('sex'));
    yield put({type: SENT_CODE_SUCCESS});
    yield put(hideModalCreator(modalName));
    yield put(success({
        title: "Email erfolgreich versendet",
        message: `Die E-Mail mit dem Aktivierungslink wurde erfolgreich an den Kursleiter ${user.get('firstname')} ${user.get('lastname')} versendet!`
    }));
}

export function* usersByActivationCode(action) {
    try {
        const user = yield call(Api.getUserByActivationCode, action.activationCode);

        yield put({type: USER_BY_ACTIVATION_CODE_SUCCESS, user: user});
    } catch (ex) {
        yield put(error({
            title: 'Ungültiger Aktivierungscode',
            message: 'Der eingegebene Aktivierungscode ist ungültig. Sie wurden auf die Loginseite umgeleitet.'
        }))
    }
}

export function* usersRegister(action) {
    const {activationCode, password, passwordRepeat, setSubmitting} = action;

    try {
        yield call(Api.registerUser, activationCode, password, passwordRepeat);

        yield put(success({
            title: 'Registrierung erfolreich',
            message: 'Das neue Passwort wurder erfolgreich gesetzt und Sie können die Anwendung nun verwenden.'
        }));
        yield put(redirect({type: REGISTER_SUCCESS}));
    } catch (ex) {
        yield put(error({
            title: 'Registrierung fehlgeschlagen',
            message: 'Es ist ein Fehler beim Setzen des neuen Passworts aufgetreten. Bitte versuchen Sie es später erneut.'
        }));
    }

    setSubmitting(false);
}

export function* fetchCoursesForCoachSaga(action) {
    try {
        const courses = yield call(Api.fetchCoursesForCoach, action.id);

        yield put(fetchCoursesForCoachSuccessCreator(courses, action.id));
    } catch (ex) {
        console.log('error while querying courses for coach with id=[', action.id, ']', ex);
    }
}