import React from 'react'
import {connect} from "react-redux";

class LoadingIndicator extends React.Component {
    render() {
        const {isLoading} = this.props;

        console.log("LOADING UPDATED");

        return <div id="loading-wrapper">
            {isLoading &&
            <div id="loading-wrapper-inner">
                <div className="modal-backdrop fade show"></div>
                <div className="sua-loader"/>
            </div>}
        </div>

    }
}

export default connect((state) => ({
    isLoading: state.loading.get("isLoading")
}))(LoadingIndicator);