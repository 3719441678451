import {Map} from 'immutable';

const initialState = Map(),
    _initialState = Map({
        isOpen: true,
        backdrop: true,
        className: ''
    }),
    HIDE_MODAL = 'HIDE_MODAL',
    SHOW_MODAL = 'SHOW_MODAL',
    INIT_MODAL = 'INIT_MODAL',
    hideModalCreator = (modalName) => ({type: HIDE_MODAL, modalName: modalName}),
    showModalCreator = (modalName, props) => ({type: SHOW_MODAL, modalName: modalName, props: props}),
    initModalCreator = (modalName) => ({type: INIT_MODAL, modalName: modalName}),
    actionMap = {
        [HIDE_MODAL]: hideModal,
        [SHOW_MODAL]: showModal,
        [INIT_MODAL]: initModal
    };

function initModal(state, action) {
    return state.set([action.modalName], _initialState.merge(action.initialState));
}

function hideModal(state, action) {
    return state.setIn([action.modalName, 'isOpen'], false);
}

function showModal(state, action) {
    return state.setIn([action.modalName, 'isOpen'], true).mergeIn([action.modalName], action.props);
}

function modalReducer(state = initialState, action) {
    if (actionMap[action.type] && typeof actionMap[action.type] === "function") {
        return actionMap[action.type](state, action);
    }

    return state;
}

export {
    hideModalCreator, showModalCreator, initModalCreator
};

export default modalReducer;