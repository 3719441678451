import React from "react";
import {connect} from "react-redux";
import {
    Button,
    Form,
    Input,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Modal,
    InputGroup,
    InputGroupAddon,
    Label,
    FormGroup,
    InputGroupText
} from "reactstrap";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";
import {ADD_PAYMENT, UNDO_PAYMENT} from "../../actions";
import {Field, Formik} from "formik";

const customInputForm = ({field, form: {touched, errors}, ...props}) => (
    <Input
        invalid={!!(touched[field.name] && errors[field.name])}
        {...field}
        {...props} />
);

class AddPaymentModal extends React.Component {

    constructor(props) {
        super(props);
        this.props.initModal();
    }

    render() {
        const {id, payment, isOpen, hideModal, backdrop, name, addPayment, undoPayment, amount, activeSeason} = this.props,
            self = this;

        let successBtnTxt, bodyTxt, titleTxt;
        if (payment) {
            successBtnTxt = "Zahlung entfernen";
            titleTxt = "Zahlung entfernen";
            bodyTxt = "Sie sind dabei die Zahlung von € " + payment.get('amount') + ", die das Mitglied " + name +
                " geleistet hat, wieder zu entfernen. Sind Sie sicher, dass Sie das tun möchten?";
        } else {
            successBtnTxt = "Zahlung hinzufügen";
            titleTxt = "Zahlung hinzufügen";
            bodyTxt = "Bitte geben Sie den Betrag ein den das Mitglied <strong>" + name + "</strong> für die aktuelle Saison eingezahlt hat:";
        }

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>{titleTxt}</ModalHeader>
            <Formik
                initialValues={{addPaymentAmount: amount}}
                onSubmit={(values, actions) =>
                    payment ? undoPayment(id, activeSeason.get('season'), name, amount) : addPayment(id, values.addPaymentAmount, activeSeason.get('season'), name)
                }>
                {props => (
                    <Form className="form-inline" onSubmit={props.handleSubmit}>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{__html: bodyTxt}}/>
                            {
                                !payment &&

                                <FormGroup>
                                    <Label className="sr-only" htmlFor="add-payment-amount">Eingezahlter Betrag</Label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>€</InputGroupText>
                                        </InputGroupAddon>
                                        <Field id="add-payment-amount" name="addPaymentAmount" component={customInputForm}
                                               placeholder="Eingezahlter Betrag..." type="number" step="any"/>
                                    </InputGroup>
                                </FormGroup>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" onClick={hideModal}>Abbrechen</Button>
                            <Button type="submit" color="primary">{successBtnTxt}</Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>;
    }
}

export default connect((state, ownProps) => ({
    activeSeason: state.sua.get("activeSeason"),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop']),
    payment: state.modal.getIn([ownProps.modalName, 'payment']),
    name: state.modal.getIn([ownProps.modalName, 'name']),
    id: state.modal.getIn([ownProps.modalName, 'id']),
    amount: state.modal.getIn([ownProps.modalName, 'amount'])
}), (dispatch, ownProps) => ({
    addPayment: (id, amount, year, name) => (dispatch({
        type: ADD_PAYMENT,
        memberId: id,
        amount: amount,
        name: name,
        year: year,
        modalName: ownProps.modalName,
        tableName: ownProps.tableName
    })),
    undoPayment: (id, year, name, amount) => (dispatch({
        type: UNDO_PAYMENT,
        memberId: id,
        year: year,
        name: name,
        amount: amount,
        modalName: ownProps.modalName,
        tableName: ownProps.tableName
    })),
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName))
}))(AddPaymentModal);