import React from "react";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {hideModalCreator, initModalCreator} from "../reducers/modalReducer";
import {DELETE_PAYMENT_TYPE} from "../actions";
import {Map} from "immutable";

class DeletePaymentTypeModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {paymentTypeDelete, isOpen, backdrop, deletePaymentType, hideModal} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Löschen der Beitragsgruppe</ModalHeader>
            <ModalBody>
                Sind Sie sicher dass Sie die Beitragsgruppe "{paymentTypeDelete.get('name')}" löschen möchten? Alle Daten dieser
                Beitragsgruppe gehen dabei verloren.
            </ModalBody>
            <ModalFooter>
                <Button type="button" color='secondary' onClick={() => hideModal()}>Abbrechen</Button>
                <Button type="submit" color='primary'
                        onClick={() => deletePaymentType(paymentTypeDelete)}>Löschen</Button>
            </ModalFooter>
        </Modal>
    }
}

export default connect((state, ownProps) => ({
    paymentTypeDelete: state.modal.getIn([ownProps.modalName, 'paymentTypeDelete']) || Map(),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
    deletePaymentType: (paymentType) => (dispatch({
        type: DELETE_PAYMENT_TYPE,
        paymentType: paymentType,
        modalName: ownProps.modalName
    }))
}))(DeletePaymentTypeModal);