/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import {routesMap} from "routesMap";
import suaReducer from "reducers/suaReducer";
import tableReducer from "reducers/tableReducer";
import loadingReducer from "reducers/loadingReducer";
import editUserModalReducer from "reducers/modals/editUserModalReducer";
import rootSaga from "saga";
import createHistory from "rudy-history/createBrowserHistory";
import {connectRoutes, redirect} from 'redux-first-router';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {reducer as notifications} from 'react-notification-system-redux';
import createSagaMiddleware from 'redux-saga';
import reduxClipboardCopy from 'redux-clipboard-copy';
import App from "./components/App";
import {Provider} from "react-redux";
import pageReducer from "./reducers/pageReducer";
import {LOGIN_PAGE, REGISTER} from "./types";
import thunk from 'redux-thunk';
import modalReducer from "./reducers/modalReducer";

const history = createHistory(),
    {reducer, middleware, enhancer, initialDispatch} = connectRoutes(routesMap, {
        createHistory,
        basename: '',
        initialDispatch: false,
        onBeforeChange: (dispatch, getState, bag) => {
            const jwtAccessToken = localStorage.getItem('jwtAccessToken'),
                jwtRefreshToken = localStorage.getItem('jwtRefreshToken');

            getState().sua.set('jwtRefreshToken', jwtRefreshToken).set('jwtAccessToken', jwtAccessToken);

            if (!getState().sua.get('jwtAccessToken') && !getState().sua.get('jwtRefreshToken') && bag.action
                && bag.action.type !== LOGIN_PAGE && bag.action.type !== REGISTER) {
                dispatch(redirect({type: LOGIN_PAGE}));
            }
        }
    }), // yes, 3 redux aspects
    sagaMiddleware = createSagaMiddleware(),

    // and you already know how the story ends:
    rootReducer = combineReducers({
        location: reducer,
        notifications,
        sua: suaReducer,
        loading: loadingReducer,
        editUser: editUserModalReducer,
        page: pageReducer,
        table: tableReducer,
        modal: modalReducer
    }),

    middlewares = applyMiddleware(sagaMiddleware, middleware, thunk, reduxClipboardCopy),

    // note the order: enhancer, then middlewares
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose,
    store = createStore(rootReducer, composeEnhancers(enhancer, middlewares));

sagaMiddleware.run(rootSaga);

initialDispatch();

ReactDOM.render(
    <div>
        <Provider store={store}>
            <App/>
        </Provider>
    </div>,
    document.getElementById("root")
);
