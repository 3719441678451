import {call, put} from "redux-saga/effects";
import Api from "../api/Api";
import {
    CREATED_PAYMENT_TYPE_FAILED,
    CREATED_PAYMENT_TYPE_SUCCESS,
    DELETE_PAYMENT_TYPE_FAILED,
    DELETE_PAYMENT_TYPE_SUCCESS,
    LIST_PAYMENT_TYPES_COMPLETE
} from "../actions";
import {error, success} from "react-notification-system-redux";
import {hideModalCreator} from "../reducers/modalReducer";

export function* paymentTypesList(action) {
    const paymentTypes = yield call(Api.fetchPaymentTypes);
    console.log("in generator function", paymentTypes);
    yield put({type: LIST_PAYMENT_TYPES_COMPLETE, paymentTypes: paymentTypes});
}

export function* paymentTypesDelete(action) {
    const {paymentType, modalName} = action;

    try {
        yield call(Api.deletePaymentType, paymentType.get('businessKey'));

        const paymentTypes = yield call(Api.fetchPaymentTypes);

        yield put(success({
            title: 'Beitragsgruppe erfolgreich gelöscht',
            message: `Die Beitragsgruppe "${paymentType.get('name')}" wurde erfolgreich gelöscht.`
        }));
        yield put({type: DELETE_PAYMENT_TYPE_SUCCESS, paymentTypes: paymentTypes});
        yield put(hideModalCreator(modalName));
    } catch (deletePaymentTypeError) {
        yield put({type: DELETE_PAYMENT_TYPE_FAILED});

        yield put(error({
            title: 'Fehler aufgetreten',
            message: 'Beim Löschen einer Beitragsgruppe ist ein unerwarteter Fehler aufgetreten.',
        }));

    }
}

export function* paymentTypesAdd(action) {
    const {paymentType, resetForm, setSubmitting} = action,
        isEdit = !!paymentType.get('businessKey');

    try {
        if (isEdit) {
            yield call(Api.updatePaymentType, paymentType);
        } else {
            yield call(Api.addPaymentType, paymentType);
        }
        const paymentTypes = yield call(Api.fetchPaymentTypes);

        setSubmitting(false);

        yield put({
            type: CREATED_PAYMENT_TYPE_SUCCESS,
            paymentTypes: paymentTypes
        });

        yield put(success({
            title: isEdit ? "Beitragsgruppe aktualisiert" : `Neue Beitragsgruppe "${paymentType.get('name')}" hinzugefügt`,
            message: isEdit ? `Die Beitragsgruppe ${paymentType.get('name')} wurde erfolgreich aktualisiert` :
                `Die Beitragsgruppe ${paymentType.get('name')} wurde erfolgreich hinzugefügt.`
        }));

        if (!isEdit) {
            resetForm({});
        } else {
            yield put(hideModalCreator(action.modalName));
        }

    } catch (errorPaymentType) {
        yield put({
            type: CREATED_PAYMENT_TYPE_FAILED
        });

        yield put(error({
            title: 'Fehler aufgetreten',
            message: 'Beim Hinzufügen einer neuen Beitragsgruppe ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        }));
    }
}