import {DASHBOARD_DATA_COMPLETE, SHOW_MODAL, UPDATED_FAMILY_PAYMENTS} from "../actions";
import {call, put, select} from "redux-saga/effects";
import Api from "../api/Api";
import {FAMILY_MEMBERS_UPDATED_MODAL} from "../modals";
import {fromJS, Map} from "immutable";

//const isAdmin = state => state.sua.getIn(["user", "roles"]).includes("ADMIN");

export function* dashboardData() {
    console.log("GETTING dashboard data");

    const state = yield select();
    const activeSeason = state.sua.get('activeSeason');

    const dashboardData = yield call(Api.getDashboardData, activeSeason ? activeSeason.get('season') : null);
    yield put({type: DASHBOARD_DATA_COMPLETE, dashboardData: dashboardData});
}

export function* fetchDashboardDataForSeason(action) {
    const dashboardData = yield call(Api.getDashboardData, action.season);
    yield put({type: DASHBOARD_DATA_COMPLETE, dashboardData: dashboardData});
}

export function* dashboardUpdateFamilyPayments() {
    console.log("UPDATE family payments called");

    const updatedMembers = yield call(Api.updateFamilyPayments);

    yield put({type: UPDATED_FAMILY_PAYMENTS});
    yield put({
        type: SHOW_MODAL,
        selector: "#" + FAMILY_MEMBERS_UPDATED_MODAL,
        modalProps: Map({
            members: fromJS(updatedMembers)
        })
    });
}