import React from "react";
import {Alert, Form, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {DELETE_USER} from "../../actions";
import {connect} from "react-redux";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";
import {fetchCoursesForCoachCreator} from "../../api/apiCreators";

class DeleteUserModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    getUserFullName() {
        const {user} = this.props;

        return user ? `${user.get('firstname')} ${user.get('lastname')}` : '';
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const oldUserId = prevProps.user && prevProps.user.get('businessKey') ? prevProps.user.get('businessKey') : null;
        const newUserId = this.props.user && this.props.user.get('businessKey') ? this.props.user.get('businessKey') : null;

        if (oldUserId !== newUserId) {
            this.props.fetchCoursesForCoach(newUserId);
        }
    }

    render() {
        const {user, isOpen, hideModal, backdrop, deleteUser, userTableName, courseNames} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Löschen des Benutzers {this.getUserFullName()}</ModalHeader>
            <ModalBody>
                Sind Sie sicher dass Sie den Benutzer <strong>{this.getUserFullName()}</strong> löschen möchten?
                Alle Daten dieses Benutzers gehen dabei verloren.
                {courseNames && !courseNames.isEmpty() &&
                <div className='mt-5'>
                    <Alert color="danger">
                        <div><strong>Achtung</strong></div>
                        <div className='mt-2'>
                            Dieser Benutzer ist aktuell Kursleiter der folgenden Kurse:
                        <ul>
                            {courseNames.map((value, key) =>
                                <li key={key}>{value}</li>
                            )}
                        </ul>
                        </div>
                    </Alert>
                </div>
                }
            </ModalBody>
            <ModalFooter>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    deleteUser(user, userTableName)
                }}>
                    <Button type="button" color="secondary" onClick={hideModal}>Abbrechen</Button>
                    <Button type="submit" className="primary">Löschen</Button>
                </Form>
            </ModalFooter>
        </Modal>
    }
}

DeleteUserModal = connect((state, ownProps) => ({
    user: state.modal.getIn([ownProps.modalName, 'user']),
    courseNames: state.sua.getIn(['coursesForCoach']),
    userTableName: state.modal.getIn([ownProps.modalName, 'userTableName']),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
    fetchCoursesForCoach: (id) => dispatch(fetchCoursesForCoachCreator(id)),
    deleteUser: (user, userTableName) => (dispatch({
        type: DELETE_USER,
        user: user,
        userTableName: userTableName,
        modalName: ownProps.modalName
    }))
}))(DeleteUserModal);

export default DeleteUserModal;