import React from "react";
import {connect} from "react-redux";
import {
    Button,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import {Field, Form, Formik} from "formik";
import {CREATE_OR_UPDATE_PAYMENT_TYPE} from "../actions";
import {customInputCheckbox, customInputForm} from "../customComponents";
import {Map} from "immutable";
import {number, object, string} from "yup";
import {hideModalCreator, initModalCreator} from "../reducers/modalReducer";

class AddPaymentTypeModal extends React.Component {

    constructor(props) {
        super(props);

        this.PaymentTypeSchema = object().shape({
            name: string().required('Pflichtfeld'),
            shortName: string().required('Pflichtfeld'),
            amount: number('Muss eine Zahl sein').min(0, 'Darf nicht negativ sein').required('Pflichtfeld'),
            notes: string()
        });

        const {initModal} = this.props;

        initModal();
    }

    getMaxIdx() {
        if (this.props.paymentType) {
            return this.props.paymentType.get("amounts").reduce((iMax, x, i, arr) => x > arr.getIn([iMax, "year"]) ? i : iMax, 0);
        }

        return 0;
    }

    getAmountSize() {
        if (this.props.paymentType && this.props.paymentType.get("amounts")) {
            return this.props.paymentType.get("amounts").size;
        }

        return 0;
    }

    getAmountName() {
        if (this.getAmountSize() > 0) {
            return "amounts[" + this.getMaxIdx() + "].amount";
        } else {
            return "amounts[0].amount";
        }
    }

    getAmountValue() {
        if (this.getAmountSize() > 0) {
            return this.props.paymentType.getIn(["amounts", this.getMaxIdx(), "amount"]);
        } else {
            return 0;
        }
    }

    isEdit() {
        const {paymentTypeEdit} = this.props;

        return paymentTypeEdit && paymentTypeEdit.get('businessKey');
    }

    checkPaymentType(prop, defaultValue) {
        const {paymentTypeEdit} = this.props;

        return this.isEdit() ? paymentTypeEdit.get(prop) : (defaultValue ? defaultValue : '');
    }

    checkRequiredField(prop) {
        const {paymentTypeEdit} = this.props;
        const requiredFields = paymentTypeEdit.get('requiredFields');

        return requiredFields ? requiredFields.includes(prop) : false;
    }

    render() {
        const {paymentTypeEdit, isOpen, backdrop, hideModal, createOrUpdate} = this.props,
            title = this.isEdit() ? "Beitragsgruppe bearbeiten" : "Neue Beitragsgruppe hinzufügen",
            desc = this.isEdit() ? "Bitte ändern Sie hier die gewünschten Daten der Beitragsgruppe" : "Bitte geben Sie die Daten der neuen Beitragsgruppe ein:",
            successBtnTxt = this.isEdit() ? "Speichern" : "Hinzufügen";

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>
                {title}
            </ModalHeader>
            <Formik enableReinitialize
                    initialValues={{
                        name: this.checkPaymentType('name'),
                        shortName: this.checkPaymentType('shortName'),
                        amount: this.checkPaymentType('amount'),
                        multiple: this.checkPaymentType('multiple', false),
                        notes: this.checkPaymentType('notes'),
                        sex: this.checkRequiredField('sex'),
                        birthdate: this.checkRequiredField('birthdate'),
                        courses: this.checkRequiredField('courses')
                    }}
                    validationSchema={this.PaymentTypeSchema}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                        const requiredFields = [];
                        if (values.courses === true) {
                            requiredFields.push('courses');
                        }
                        if (values.birthdate === true) {
                            requiredFields.push('birthdate');
                        }
                        if (values.sex === true) {
                            requiredFields.push('sex');
                        }
                        createOrUpdate(paymentTypeEdit.merge({
                            name: values.name,
                            shortName: values.shortName,
                            amount: values.amount,
                            notes: values.notes,
                            requiredFields: requiredFields
                        }), setSubmitting, resetForm);
                    }}>
                {({isSubmitting}) => (
                    <Form>
                        <ModalBody>
                            <h3 className='mb-2'>
                                {desc}
                            </h3>
                            <fieldset id="add-payment-type-modal-fieldset">
                                <FormGroup>
                                    <Label for="name">Bezeichnung</Label>
                                    <Field name="name" className="form-control" type="text"
                                           placeholder="Bezeichnung..." component={customInputForm}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="shortName">Kurzbezeichnung</Label>
                                    <Field name="shortName" className="form-control" type="text"
                                           placeholder="Kurzbezeichnung..." component={customInputForm}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="multiple">Familienbeitrag</Label>
                                    <InputGroup className='mb-3'>
                                        <Field name="multiple" className='form-control' type='checkbox'
                                               component={customInputCheckbox}/>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="amount">Betrag</Label>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-euro-sign"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>

                                        <Field name="amount" type="number" min={0}
                                               placeholder="Betrag..." component={customInputForm}/>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Pflichtfelder</Label>
                                    <InputGroup>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Field id='birthdate' type='checkbox' name='birthdate'
                                                       component={customInputCheckbox}/>
                                                Geburtsdatum
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Field id='courses' type='checkbox' name='courses'
                                                       component={customInputCheckbox}/>
                                                Kurs
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline>
                                            <Label check>
                                                <Field id='sex' type='checkbox' name='sex'
                                                       component={customInputCheckbox}/>
                                                Geschlecht
                                            </Label>
                                        </FormGroup>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="notes">Anmerkungen</Label>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-align-left-2"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Field id="notes" name="notes" rows="5" type="textarea"
                                               placeholder="Anmerkungen..." component={customInputForm}/>
                                    </InputGroup>
                                </FormGroup>
                            </fieldset>
                        </ModalBody>
                        <ModalFooter>
                            <Button type='button' color='secondary' onClick={() => hideModal()}>Abbrechen</Button>
                            <Button type='submit' color='primary' disabled={isSubmitting}>{successBtnTxt}</Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    }
}

export default connect((state, ownProps) => ({
    paymentTypeEdit: state.modal.getIn([ownProps.modalName, 'paymentTypeEdit']) || Map(),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName)),
    createOrUpdate: (paymentType, setSubmitting, resetForm) => (dispatch({
        type: CREATE_OR_UPDATE_PAYMENT_TYPE,
        paymentType: paymentType,
        modalName: ownProps.modalName,
        setSubmitting: setSubmitting,
        resetForm: resetForm
    }))
}))(AddPaymentTypeModal);