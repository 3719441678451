export const DASHBOARD = "DASHBOARD",
    DASHBOARD2 = "DASHBOARD2",
    MEMBERS_LIST = "MEMBERS_LIST",
    MEMBERS_DETAILS = "MEMBER_DETAILS",
    MEMBERS_CREATE = "MEMBERS_CREATE",
    MEMBERS_FILTER = "MEMBERS_FILTER",
    MEMBERS_DELETE = "MEMBERS_DELETE",
    COURSES_LIST = "COURSES_LIST",
    COURSES_CREATE = "COURSES_CREATE",
    SEASONS_LIST = "SEASONS_LIST",
    SEASONS_ADD = "SEASONS_ADD",
    MEMBERS_FOR_SEASON = "MEMBERS_FOR_SEASON",
    ALL_MEMBERS = "ALL_MEMBERS",
    MEMBERS_OF_CURRENT_SEASON = "MEMBERS_OF_CURRENT_SEASON",
    PAYMENT_TYPES_LIST = "PAYMENT_TYPES_LIST",
    USERS_LIST = "USERS_LIST",
    COACH_LIST = "COACH_LIST",
    ADMIN = "ADMIN",
    LOGIN_PAGE = "LOGIN_PAGE",
    COURSE_CATEGORIES = "COURSE_CATEGORIES",
    COURSE_CATEGORIES_CREATE = "COURSE_CATEGORIES_CREATE",
    REGISTER = 'REGISTER',
    REGISTER_SUCCESS = 'REGISTER_SUCCESS',
    COURSES_LIST_OWNER = 'COURSES_LIST_OWNER',
    COURSES_LIST_OWNER_ID = 'COURSES_LIST_OWNER_ID',
    CURRENT_USER = 'CURRENT_USER';