/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import ServerSideTable from "../../components/ServerSideTable";
// core components
import AdminHeader from '../../components/Headers/AdminHeader';
import {connect} from "react-redux";
import {restMap, routeCreator} from "../../routesMap";
import {MEMBERS_CREATE, MEMBERS_DETAILS, MEMBERS_LIST} from "../../types";
import {SEX} from "../../enums";
import {List, Map} from "immutable";
import Moment from "react-moment";
import AddPaymentModal from "./AddPaymentModal";
import {showModalCreator} from "../../reducers/modalReducer";
import {addMemberToCurrentSeasonCreator, deleteMemberFromCurrentSeasonCreator} from "../../saga";
import {
    addGetParamCreator,
    changePageCreator,
    fetchDataThunk,
    removeGetParamCreator
} from "../../reducers/tableReducer";
import {PAYMENT_TYPE, SEASON} from "../../constants";
import AddCourseModal from "./AddCourseModal";
import DeleteMemberModal from "./DeleteMemberModal";
import Select from "react-select";

class Members extends React.Component {

    constructor(props) {
        super(props);

        this.addPaymentModalName = 'AddPaymentModal';
        this.addCourseModalName = 'AddCourseModal';
        this.deleteMemberModalName = 'DeleteMemberModal';
        this.tableName = 'members';
    }

    isActive(fullData) {
        const {activeSeason} = this.props;

        return activeSeason && fullData.get('activeSeasons') && fullData.get('activeSeasons').includes(activeSeason.get('season'));
    }

    hasPaid(fullData) {
        const {activeSeason} = this.props;

        return activeSeason && fullData.get('payments') && fullData.get('payments').has(activeSeason.get('season').toString());
    }

    getCourseCount(courses) {
        const {activeSeason} = this.props;

        return activeSeason && courses.get(activeSeason.get('season').toString()) && courses.get(activeSeason.get('season').toString()).size ?
            courses.get(activeSeason.get('season').toString()).size + (courses.get(activeSeason.get('season').toString()).size === 1 ? ' Kurs' : ' Kurse') : 'Keine Kurse';
    }

    findPaymentAmountInPaymentTypes(paymentTypeOfMember) {
        const {paymentTypes} = this.props;
        const paymentType = paymentTypes.find((paymentType) => paymentType.get('businessKey') === paymentTypeOfMember.get('businessKey'));
        return paymentType ? paymentType.get('amount') : null;
    }

    getDataForPaymentModal(member) {
        const {activeSeason} = this.props;

        return Map({
            payment: member.getIn(['payments', activeSeason.get('season') ? activeSeason.get('season').toString() : '']),
            name: member.get('firstname') + ' ' + member.get('lastname'),
            id: member.get('businessKey'),
            amount: this.findPaymentAmountInPaymentTypes(member.get('paymentType'))
        });
    }

    getPaymentTypeOptions() {
        const {paymentTypes} = this.props;

        const paymentTypeOptions = [{
            value: '',
            label: 'Alle'
        }]

        return paymentTypeOptions.concat(paymentTypes.map(paymentType => ({
            value: paymentType.get('businessKey'),
            label: paymentType.get('name')
        })).toJS());
    }

    render() {
        const {
            gotoMemberCreate, gotoFirstPage, showAddCourseModal, activeSeason, showAddPaymentModal, showDeleteMemberModal,
            addToCurrentSeason, deleteFromCurrentSeason, tableParams, addOnlyActiveFilter, removeOnlyActiveFilter, fetchTableData,
            gotoMemberDetails, setPaymentTypeFilter, isCurrentSeasonHighest
        } = this.props;

        console.log('isCurrentSeasonHighest', isCurrentSeasonHighest, this.props.isCurrentSeasonHighest);

        const columnsList = List([
            Map([
                ['key', 'status'],
                ['valueConverter', (val, fullData) => <div>
                                            <span>{this.isActive(fullData) ?
                                                <i className="fas fa-user-check text-success"
                                                   onClick={() => deleteFromCurrentSeason(this.tableName, fullData.get('businessKey'))}></i> :
                                                <i className="fas fa-user-times text-danger"
                                                   onClick={() => addToCurrentSeason(this.tableName, fullData.get('businessKey'))}></i>}</span>
                    <span className='pl-1'>{this.hasPaid(fullData) ?
                        <i className="fas fa-euro-sign text-success"
                           onClick={() => showAddPaymentModal(this.addPaymentModalName, this.getDataForPaymentModal(fullData))}></i> :
                        <i className="fab fa-creative-commons-nc-eu text-danger"
                           onClick={() => showAddPaymentModal(this.addPaymentModalName, this.getDataForPaymentModal(fullData))}></i>}</span>
                </div>],
                ['sortable', false]
            ]),
            Map([['key', 'lastname'], ['display', 'Nachname']]),
            Map([['key', 'firstname'], ['display', "Vorname"]]),
            Map([['key', 'sex'], ['display', "Geschlecht"], ['valueConverter', (val) => val ? SEX[val].label : ""]]),
            Map([['key', 'birthdate'], ['display', "Geburtsdatum"], ['valueConverter', (val) => val ?
                <Moment format="DD.MM.YYYY">{val}</Moment> : ""]]),
            Map([
                ['key', 'paymentType'], ['display', "Beitrag"],
                ['sortable', false],
                //['valueConverter', (val) => val ? '€ ' + val.get('amount') : ""],
                ['classes', 'text-right'],
                ['valueConverter', (val) => {
                    const amount = this.findPaymentAmountInPaymentTypes(val);
                    return amount || amount === 0 ? '€ ' + amount : 'Bitte zuordnen';
                }]]),
            Map([
                ['key', 'courses'], ['display', "Kurse"],
                ['sortable', false],
                ['valueConverter', (val) => this.getCourseCount(val)]]),
            Map([
                ['key', 'actions'],
                ['display', "Aktionen"],
                ['sortable', false],
                ['rendered', isCurrentSeasonHighest],
                ['valueConverter', (val, fullData) => <UncontrolledDropdown>
                    <DropdownToggle
                        className="btn-icon-only text-light"
                        href="#"
                        role="button"
                        size="sm"
                        color=""
                        onClick={e => e.preventDefault()}
                    >
                        <i className="fas fa-ellipsis-v"/>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                gotoMemberDetails(fullData.get('businessKey'));
                            }}
                        >
                            <i className="fas fa-pencil-alt"></i>
                            Editieren
                        </DropdownItem>
                        <DropdownItem
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                showAddCourseModal(this.addCourseModalName, Map([['member', fullData]]));
                            }}
                        >
                            <i className="fa fa-plus"></i>
                            Kurse bearbeiten
                        </DropdownItem>
                        <DropdownItem
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                showDeleteMemberModal(this.deleteMemberModalName, Map([['member', fullData]]));
                            }}
                        >
                            <i className="far fa-trash-alt"></i>
                            Löschen
                        </DropdownItem>
                        <DropdownItem
                            href="#"
                            onClick={() => showAddPaymentModal(this.addPaymentModalName, this.getDataForPaymentModal(fullData))}>
                            <i className="fas fa-euro-sign"></i>
                            {this.hasPaid(fullData) ? ' Zahlung entfernen' : ' Zahlung hinzufügen'}
                        </DropdownItem>
                        <DropdownItem
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                this.isActive(fullData) ? deleteFromCurrentSeason(this.tableName, fullData.get('businessKey')) : addToCurrentSeason(this.tableName, fullData.get('businessKey'));
                            }}>
                            {this.isActive(fullData) ? <i className="fas fa-check"></i> :
                                <i className="fas fa-sync"></i>}
                            {this.isActive(fullData) ? ' Inaktiv setzen' : ' Aktiv setzen'}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                ]]),
        ]);

        return (
            <>
                <AdminHeader/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0 float-left">Mitglieder</h3>

                                    <div className='float-right'>
                                        <Select options={this.getPaymentTypeOptions()} onChange={(e) => {
                                            setPaymentTypeFilter(this.tableName, e.value);
                                            gotoFirstPage(this.tableName);
                                            fetchTableData(this.tableName);
                                        }}  id='payment-filter'/>
                                        <Button
                                            color={activeSeason && tableParams && tableParams.get(SEASON) === activeSeason.get('season') ? 'primary' : 'secondary'}
                                            onClick={() => {
                                                tableParams.get(SEASON) === activeSeason.get('season') ? removeOnlyActiveFilter(this.tableName) : addOnlyActiveFilter(this.tableName, activeSeason.get('season'));
                                                gotoFirstPage(this.tableName);
                                                fetchTableData(this.tableName);
                                            }}>Nur Aktive</Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row className='float-left'>
                                        <Col md={2}>
                                            <Button type='button' color='primary' onClick={() => gotoMemberCreate()}>Neues
                                                Mitglied
                                                anlegen</Button>
                                        </Col>
                                    </Row>
                                    <ServerSideTable columns={columnsList} api={restMap[MEMBERS_LIST]} tableName={this.tableName} sort={1}
                                                     disableReinit={true}>
                                    </ServerSideTable>
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col md={2}>
                                            <Button type='button' color='primary' onClick={() => gotoMemberCreate()}>Neues
                                                Mitglied
                                                anlegen</Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                    <AddPaymentModal modalName={this.addPaymentModalName} tableName={this.tableName}/>
                    <AddCourseModal modalName={this.addCourseModalName} memberTableName={this.tableName}
                                    tableName='AddCourseTable'/>
                    <DeleteMemberModal modalName={this.deleteMemberModalName} tableName={this.tableName}/>
                </Container>
            </>
        );
    }
}

export default connect(state => ({
        activeSeason: state.sua.get('activeSeason'),
        tableParams: state.table.getIn(['members', 'params']),
        paymentTypes: state.sua.get('paymentTypes'),
        isCurrentSeasonHighest: state.sua.get('isCurrentSeasonHighest')
    }),
    (dispatch, ownProps) => ({
        showAddPaymentModal: (paymentModalName, props) => dispatch(showModalCreator(paymentModalName, props)),
        showAddCourseModal: (courseModalName, props) => dispatch(showModalCreator(courseModalName, props)),
        showDeleteMemberModal: (deleteMemberModalName, props) => dispatch(showModalCreator(deleteMemberModalName, props)),
        addToCurrentSeason: (tableName, memberId) => dispatch(addMemberToCurrentSeasonCreator(tableName, memberId)),
        deleteFromCurrentSeason: (tableName, memberId) => dispatch(deleteMemberFromCurrentSeasonCreator(tableName, memberId)),
        addOnlyActiveFilter: (tableName, season) => dispatch(addGetParamCreator(tableName, SEASON, season)),
        removeOnlyActiveFilter: (tableName) => dispatch(removeGetParamCreator(tableName, SEASON)),
        setPaymentTypeFilter: (tableName, paymentTypeBusinessKey) => dispatch(addGetParamCreator(tableName, PAYMENT_TYPE, paymentTypeBusinessKey)),
        fetchTableData: (tableName) => dispatch(fetchDataThunk(tableName)),
        gotoFirstPage: (tableName) => dispatch(changePageCreator(tableName, 1)),
        gotoMemberCreate: () => dispatch(routeCreator(MEMBERS_CREATE)),
        gotoMemberDetails: (id) => dispatch(routeCreator(MEMBERS_DETAILS, {payload: {id: id}}))
    }))(Members);
