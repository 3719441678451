import React from "react";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {DELETE_COURSE} from "../../actions";
import {hideModalCreator, initModalCreator} from "../../reducers/modalReducer";

class DeleteCourseModal extends React.Component {

    constructor(props) {
        super(props);

        const {initModal} = this.props;

        initModal();
    }

    render() {
        const {courseDelete, deleteCourse, hideModal, isOpen, backdrop} = this.props;

        return <Modal isOpen={isOpen} backdrop={backdrop}>
            <ModalHeader toggle={hideModal}>Kurs löschen</ModalHeader>
            <ModalBody>
                Sind Sie sicher dass Sie den Kurs <strong>{courseDelete ? courseDelete.get('name') : ''}</strong> löschen möchten? Alle Daten dieses Kurses
                gehen dabei verloren.
            </ModalBody>
            <ModalFooter>
                <Button type="button" color='secondary' onClick={() => hideModal()}>Abbrechen</Button>
                <Button type="submit" color='primary' onClick={() => deleteCourse(courseDelete)}>Löschen</Button>
            </ModalFooter>
        </Modal>
    }
}

export default connect((state, ownProps) => ({
    courseDelete: state.modal.getIn([ownProps.modalName, 'courseDelete']),
    isOpen: state.modal.getIn([ownProps.modalName, 'isOpen']),
    backdrop: state.modal.getIn([ownProps.modalName, 'backdrop'])
}), (dispatch, ownProps) => ({
    deleteCourse: (courseDelete) => (dispatch({
        type: DELETE_COURSE,
        courseDelete: courseDelete,
        modalName: ownProps.modalName
    })),
    initModal: () => dispatch(initModalCreator(ownProps.modalName)),
    hideModal: () => dispatch(hideModalCreator(ownProps.modalName))
}))(DeleteCourseModal);