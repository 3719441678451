import Api from "../api/Api";
import {call, put} from 'redux-saga/effects';
import {
    CREATE_MEMBER_FAILED, CREATE_MEMBER_SUCCESS, FETCH_MEMBER_DETAIL_COMPLETE,
    HIDE_MODAL,
    LIST_MEMBERS_COMPLETE,
    SHOW_MODAL,
    UPDATE_MEMBER_FAILED,
    UPDATED_MEMBER
} from "../actions";
import {MEMBERS_LIST} from "../types";
import {error, success} from 'react-notification-system-redux';
import {MEMBER_EXISTS_MODAL} from "../modals";
import {Map} from "immutable";
import {fromJS} from "immutable";
import {hideModalCreator} from "../reducers/modalReducer";
import {fetchDataThunk} from "../reducers/tableReducer";

export function* membersUndoPayment(action) {
    try {
        yield call(Api.undoPayment, action.memberId, action.year);
        yield put(hideModalCreator(action.modalName));
        yield put(success({
            title: "Zahlung erfolgreich storniert!",
            message: "Eine Zahlung von " + action.amount + " von dem Mitglied " + action.name + " wurde erfolgreich storniert!"
        }));
        yield put(fetchDataThunk(action.tableName));
    } catch (undoPaymentError) {
        console.log(undoPaymentError);

        yield put(error({
            title: "Vorgang nicht erfolgreich",
            message: "Die Zahlung konnte nicht storniert werden. Bitte versuchen Sie es später erneut."
        }));
    }
}

export function* membersAddPayment(action) {
    try {
        const {payment} = yield call(Api.addPayment, action.memberId, action.year, action.amount);
        yield put(hideModalCreator(action.modalName));
        yield put(success({
            title: "Zahlung erfolgreich!",
            message: "Eine Zahlung von " + action.amount + " wurde dem Mitglied " + action.name + " erfolgreich für die aktuelle Saison hinzugefügt"
        }));
        yield put(fetchDataThunk(action.tableName));
    } catch (addPaymentError) {
        yield put(error({
            title: "Vorgang nicht erfolgreich",
            message: "Die Zahlung konnte nicht hinzugefügt werden. Bitte versuchen Sie es später erneut."
        }));
    }
}

export function* membersToCurrentSeason(action) {
    try {
        yield call(Api.addMemberToCurrentSeason, action.memberId);
        yield put(success({
            title: "Mitglied erfolgreich zur neuen Saison hinzugefügt",
            message: "Das Mitglied wurde erfolgreich zur neuen Saison hinzugefügt!"
        }));
        yield put(fetchDataThunk(action.tableName));
    } catch (errorAddToCurrentSeason) {
        console.log("ERROR", errorAddToCurrentSeason);
    }
}

export function* membersDeleteFromCurrentSeason(action) {
    yield console.log("membersToCurrentSeason called", action);

    try {
        yield call(Api.removeMemberFromCurrentSeason, action.memberId);
        yield put(success({
            title: "Mitglied erfolgreich von der aktuellen Saison entfernt",
            message: "Das Mitglied wurde erfolgreich von der aktuellen Saison entfernt!"
        }));
        yield put(fetchDataThunk(action.tableName));
    } catch (errorDeleteFromCurrentSeason) {
        console.log("ERROR", errorDeleteFromCurrentSeason);
    }
}

export function* membersAddCourses(action) {
    try {
        const {modalName, tableName, memberId, courses} = action;

        yield call(Api.addCourses, memberId, courses);
        if (modalName) {
            yield put({type: HIDE_MODAL, modalName: modalName});
        }
        yield put(success({
            title: 'Kurse erfolgreich hinzugefügt',
            message: 'Die Kurse wurden dem Mitglied erfolgreich hinzugefügt.'
        }));
        if (tableName) {
            yield put(fetchDataThunk(tableName));
        }
    } catch (addCoursesError) {
        yield put(error({
            title: 'Fehler aufgetreten',
            message: 'Beim Hinzufügen der Kurse zum Mitglied ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        }));
    }
}

export function* membersDetails(action) {
    const [member, paymentTypes, courses] = yield [
        call(Api.fetchMember, action.payload.id),
        call(Api.fetchPaymentTypes),
        call(Api.fetchCourses)
    ];

    yield put({
        type: FETCH_MEMBER_DETAIL_COMPLETE,
        member: member,
        paymentTypes: paymentTypes,
        courses: courses
    });
}

export function* membersList() {
    const [courses, paymentTypes] = yield [
        call(Api.fetchCourses),
        call(Api.fetchPaymentTypes)
    ];

    yield put({
        type: LIST_MEMBERS_COMPLETE,
        courses: courses,
        paymentTypes: paymentTypes
    });
}

export function* membersUpdate(action) {
    if (!action) {
        return;
    }

    const {member, businessKey} = action;

    try {
        const newMember = yield call(Api.updateMember, member, businessKey);
        yield put(success({
            title: 'Mitgliedsdaten aktualisiert',
            message: 'Die Daten des Mitglieds ' + member.firstname + ' ' + member.lastname + ' wurden erfolgreich aktualisiert.'
        }));
        // member muss in den state gespeichert werden.
        yield put({type: UPDATED_MEMBER, member: newMember, id: businessKey});
        yield put({type: MEMBERS_LIST});
    } catch (errorMemberCreate) {
        yield put(error({
            title: 'Fehler aufgetreten',
            message: 'Beim Aktualisieren der Mitgliedsdaten ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        }));
        yield put({type: UPDATE_MEMBER_FAILED});
    }
}

export function* membersCreate(action) {
    const {member, setSubmitting, resetForm} = action;

    try {
        const newMember = yield call(Api.createMember, member);

        setSubmitting(false);

        window.scrollTo(0, 0);

        resetForm({});

        yield put(success({
            title: 'Neues Mitglied hinzugefügt',
            message: 'Das Mitglied ' + member.firstname + ' ' + member.lastname + ' wurde erfolgreich hinzugefügt.'
        }));

        yield put({type: CREATE_MEMBER_SUCCESS});

    } catch (errorMemberCreate) {

        setSubmitting(false);

        yield put(error({
            title: 'Fehler aufgetreten',
            message: 'Beim Hinzufügen eines neuen Mitglieds ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
        }));

        yield put({type: CREATE_MEMBER_FAILED});
    }
}

export function* membersDelete(action) {
    const {member, modalName, tableName} = action;

    try {
        yield call(Api.deleteMember, member.get('businessKey'));
        yield put({type: HIDE_MODAL, modalName: modalName});

        yield put(fetchDataThunk(tableName));

        yield put(success({
            title: 'Mitglied gelöscht',
            message: `Das Mitglied ${member.get('firstname')} ${member.get('lastname')} wurde erfolgreich gelöscht.`
        }));

    } catch (errorDeleteMember) {
        yield put(error({
            title: 'Fehler beim Löschen',
            message: 'Das Mitglied konnte aufgrund eines unerwarteten Fehlers nicht gelöscht werden. Bitte versuchen Sie es später erneut.',
        }));
    }
}

export function* membersExist(action) {
    const members = yield call(Api.existsMember, action.firstname, action.lastname);

    if (members.length === 0) {
        action.resolve();
    } else {
        action.reject({
            firstname: "Diese Kombination aus Zu- und Vorname existiert bereits!",
            lastname: "Diese Kombination aus Zu- und Vorname existiert bereits!"
        });

        yield put({
            type: SHOW_MODAL,
            selector: "#" + MEMBER_EXISTS_MODAL,
            modalProps: Map({
                members: fromJS(members)
            })
        });
    }
}