import React from "react";
import {connect} from "react-redux";
import AdminHeader from "../../components/Headers/AdminHeader";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import ServerSideTable from "../../components/ServerSideTable";
import {List, Map} from "immutable";
import AddCourseModal from "./AddCourseModal";
import {showModalCreator} from "../../reducers/modalReducer";
import DeleteCourseModal from "./DeleteCourseModal";
import CourseInfoModal from "./CourseInfoModal";
import NewCoachInfoModal from "./NewCoachInfoModal";

class Courses extends React.Component {

    constructor(props) {
        super(props);

        this.addCourseModal = 'AddCourseModal';
        this.deleteCourseModal = 'DeleteCourseModal';
        this.courseInfoModal = 'CourseInfoModal';
        this.participantsTable = 'ParticipantTable';
        this.newCoachInfoModal = 'NewCoachInfoModal';
    }

    render() {
        const {courses, showCourseModal, showDeleteCourseModal, showCourseInfoModal, isAdmin} = this.props;

        return (
            <>
                <AdminHeader/>
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <h3 className="mb-0 float-left">Kurse</h3>
                                </CardHeader>
                                <CardBody>
                                    <ServerSideTable columns={List([
                                        Map([
                                            ['key', 'name'],
                                            ['display', 'Name']
                                        ]),
                                        Map([
                                            ['key', 'coach'],
                                            ['display', 'Kursleiter'],
                                            ['valueConverter', (val) => `${val.get('firstname')} ${val.get('lastname')}`]
                                        ]),
                                        Map([
                                            ['key', 'entitledCoaches'],
                                            ['display', 'Zugelassene Benutzernamen']
                                        ]),
                                        Map([
                                            ['key', 'participants'],
                                            ['display', 'Anzahl Teilnehmer'],
                                            ['valueConverter', participants => participants ? participants.size : 0]
                                        ]),
                                        Map([
                                            ['key', ''],
                                            ['display', ''],
                                            ['sortable', false],
                                            ['valueConverter', (val, fullData) => <UncontrolledDropdown
                                                id={fullData.get('businessKey')}
                                                className='position-relative'>
                                                <DropdownToggle
                                                    data-boundary={fullData.get('businessKey')}
                                                    className="btn-icon-only text-light"
                                                    href="#"
                                                    role="button"
                                                    size="sm"
                                                    color=""
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    <i className="fas fa-ellipsis-v"/>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                    {isAdmin && <><DropdownItem href="#"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    showCourseModal(this.addCourseModal, fullData)
                                                                                }}>
                                                        <i className="fas fa-pencil-alt"></i>
                                                        Editieren
                                                    </DropdownItem>
                                                        <DropdownItem href="#"
                                                                      onClick={(e) => {
                                                                          e.preventDefault();
                                                                          showDeleteCourseModal(this.deleteCourseModal, fullData)
                                                                      }}>
                                                            <i className="far fa-trash-alt"></i>
                                                            Löschen
                                                        </DropdownItem></>}
                                                    <DropdownItem
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            showCourseInfoModal(this.courseInfoModal, fullData);
                                                        }}>
                                                        <i className="fas fa-euro-sign"></i>
                                                        Teilnehmer anzeigen
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>]
                                        ])])} values={courses} isLocal={true} tableName='CourseTable'
                                                     textSearchProps={List(['name'])}>
                                    </ServerSideTable>
                                </CardBody>
                                <CardFooter>
                                    {isAdmin && <Button type='button' color='primary'
                                                        onClick={() => showCourseModal(this.addCourseModal)}>Neuen Kurs
                                        hinzufügen</Button>}
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                    <AddCourseModal modalName={this.addCourseModal} newCoachInfoModalName={this.newCoachInfoModal}/>
                    <DeleteCourseModal modalName={this.deleteCourseModal}/>
                    <CourseInfoModal modalName={this.courseInfoModal} tableName={this.participantsTable}/>
                    <NewCoachInfoModal modalName={this.newCoachInfoModal}/>
                </Container>
            </>);
    }
}

export default connect(state => ({
        courses: state.sua.get('courses'),
        isAdmin: state.sua.getIn(['user', 'roles']) && state.sua.getIn(["user", "roles"]).includes("ADMIN")
    }),
    dispatch => ({
        showCourseModal: (modalName, courseEdit) => dispatch(showModalCreator(modalName, {courseEdit: courseEdit})),
        showDeleteCourseModal: (modalName, courseDelete) => dispatch(showModalCreator(modalName, {courseDelete: courseDelete})),
        showCourseInfoModal: (modalName, courseInfo) => dispatch(showModalCreator(modalName, {courseInfo: courseInfo}))
    })
)(Courses);